import React from 'react';
import { AppBar, Toolbar, Box, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    toolbar: {
      backgroundColor: 'white',
      boxShadow: 'none',
      color: 'black',
    },
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
      color: '#000!important',

      margin: '0 0',
    },
    menu: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      color: 'white!important',
      marginRight: '18px!important',
      marginLeft: '5px!important',
    },
    textRed: {
      color: '#FE0000',
    },
    groupButton: {
      borderBottom: '1px solid #999999!important',
      borderTop: '1px solid #CCCCCC!important',
      padding: '10px 0px 10px 0px',
      display: 'block!important',
    },
  }),
);

export const HeaderTwo: React.FC = () => {
  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }} zIndex={10}>
      <AppBar>
        <Toolbar className={classes.toolbar}>
          <Box
            component={'div'}
            style={{ background: 'white' }}
            position={'fixed'}
            top={'0'}
            left={'0'}
            height={'75px'}
            justifyContent={'center'}
            alignItems={'center'}
            display={'flex'}
            width={'100%'}
          >
            <button
              className={classes.cleanButton}
              onClick={() =>
                (window.location.href = 'https://noroeste.com.mx/')
              }
            >
              <img
                src="/LOGO_NOROESTE.svg"
                alt="logo"
                width={225}
                height={25}
                style={{ flexGrow: 1 }}
              />
            </button>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
