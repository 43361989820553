import React, { useEffect, useState } from 'react';
import { useAuthFacebook } from './auth-facebook-hook';
import {
  useCreateUserMutation,
  useSignUpWithSocialProviderMutation,
  useUserByEmailLazyQuery,
} from '../../shared/types/generated';
import { MainLoader } from '../../shared/components/MainLoader';
import { getCurrentUrl } from './auth-utils';
import { DOMAIN_SITE } from '../../shared/constans';
import { useCookies } from 'react-cookie';
import moment from 'moment/moment';

export const AuthFacebookCallback: React.FC = () => {
  const url: URL = new URL(window.location.href.replace('#', ''));
  const params: URLSearchParams = url.searchParams;
  const { user, isAuthenticated } = useAuthFacebook();
  const [, setCookie] = useCookies(['_u', 'loginWithSocial']);
  const [loginWithSocial, setLoginWithSocial] = useState<string | undefined>(
    undefined,
  );

  const [signUpWithSocialProviderMutation] =
    useSignUpWithSocialProviderMutation({
      onCompleted: ({ SignUpWithSocialProviderMutation }) => {
        if (SignUpWithSocialProviderMutation?.access_token) {
          setCookie('loginWithSocial', loginWithSocial, {
            path: '/',
            domain: DOMAIN_SITE,
            expires: moment().add(1, 'year').toDate(),
          });
          setCookie('_u', SignUpWithSocialProviderMutation?.access_token, {
            path: '/',
            domain: DOMAIN_SITE,
            expires: moment().add(1, 'year').toDate(),
          });
          window.location.href = params.has('state')
            ? (params.get('state') as string)
            : '/';
        }
      },
    });

  const [createUserMutation] = useCreateUserMutation({
    onCompleted: ({ createUser }) => {
      if (createUser?.id) {
        signUpWithSocialProviderMutation({
          variables: {
            data: {
              email: createUser.email,
              provider: 'FACEBOOK',
            },
          },
        });
        setLoginWithSocial('Facebook');
      }
    },
    onError: () => {},
  });

  const [fetchUser] = useUserByEmailLazyQuery({
    onCompleted: ({ userByEmail }) => {
      if (userByEmail?.id) {
        signUpWithSocialProviderMutation({
          variables: {
            data: {
              email: userByEmail.email,
              provider: 'FACEBOOK',
            },
          },
        });
        setLoginWithSocial('Facebook');
      } else {
        createUserMutation({
          variables: {
            data: {
              email: String(user?.email),
              name: user?.name,
              avatar: user?.picture?.data?.url,
              password: `$%-/${user?.email}+2023,`,
              provider: {
                provider: 'FACEBOOK',
                urlRegister: getCurrentUrl(),
                providerId: user?.email,
              },
            },
          },
        });
      }
    },
    onError: () => {},
  });

  useEffect(() => {
    if (isAuthenticated && user?.email) {
      fetchUser({
        variables: {
          email: user?.email as string,
          isSocialProvider: true,
          provider: 'FACEBOOK',
        },
      });
    }
  }, [isAuthenticated, user, fetchUser]);

  return <MainLoader />;
};
