export enum PaymentType {
  Plan1 = 'plan1',
  Plan2 = 'plan2',
  Plan3 = 'plan3',
}

export enum PaymentTime {
  Monthly = 'monthly',
  Annually = 'annually',
  Daily = 'daily',
}
