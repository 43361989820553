export const AUTH_EMAIL = 'AUTH_EMAIL';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD';
export const AUTH_RESET_PASSWORD_DONE = 'AUTH_RESET_PASSWORD_DONE';
export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_HOME = 'AUTH_HOME';
export const AUTH_PASSWORD_EMAIL_SEND = 'AUTH_PASSWORD_EMAIL_SEND';

export const AUTH_FORGOT_PASSWORD_NOT_FOUND = 'AUTH_FORGOT_PASSWORD_NOT_FOUND';
export const FACEBOOK_CALLBACK = process.env.REACT_APP_FACEBOOK_CALLBACK;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const GOOGLE_CAPTCHA = process.env.REACT_APP_GOOGLE_CAPTCHA;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export const TARGET_ORIGIN_URL = process.env.REACT_APP_TARGET_ORIGIN_URL || '';

export const SITE_URL = process.env.REACT_APP_SITE_URL || '';

export const DOMAIN_SITE = `.${process.env.REACT_APP_DOMAIN || ''}`;

export const BASIC_PLAN = process.env.REACT_APP_BASIC_PLAN || '';
export const SEMESTRAL_PLAN = process.env.REACT_APP_SEMETRAL_PLAN || '';
export const ANUAL_PLAN = process.env.REACT_APP_ANUAL_PLAN || '';
export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID || '';
export const OPEN_PAY_PK_ID = process.env.REACT_APP_OPEN_PAY_PK_ID || '';
export const OPEN_PAY_MERCHANT_ID =
  process.env.REACT_APP_OPEN_PAY_MERCHANT_ID || '';
export const OPEN_PAY_ENV = process.env.REACT_APP_OPEN_PAY_ENV || '';
export enum PaymentSelection {
  CreditCard = 'CREDIT_CARD',
  PayPal = 'PAYPAL',
}
export const APP_ENV = process.env.REACT_APP_ENV || 'local';
export const BUGSNAG_API_KEY =
  (process.env.REACT_APP_BUGSNAG_API_KEY as string) || '';
