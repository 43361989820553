import React, { useEffect } from 'react';
import { useScript, HTMLPositionEnum } from '../utils/hooks/useScript';

//import { useRouter } from 'next/router';

type PixelFacebookEventProps = {
  type?: string;
  jsonInfo?: string;
  id?: string;
};
export const removeScript = function (id: string): null {
  if (typeof document !== 'undefined') {
    const script = document.getElementById(id);
    if (script) script.remove();
  }
  return null;
};

export const PixelFacebookEvent: React.FC<PixelFacebookEventProps> = ({
  type,
  jsonInfo,
  id = '',
}) => {
  const scriptid = 'pixel-event' + id;
  useScript({
    scriptContent: `fbq('track', '${type ? type : 'PageView'}'${
      jsonInfo ? ', ' + jsonInfo : ''
    });`,
    position: HTMLPositionEnum.Head,
    scriptId: scriptid,
  });

  useEffect(() => {
    const handleRouteChange = (): null => {
      removeScript(scriptid);
      console.log('elimino', scriptid);
      return null;
    };
    return () => {
      handleRouteChange();
    };
  }, [scriptid]);

  return null;
};
