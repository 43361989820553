import React, { useEffect } from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';

import { useAuth } from '../auth/auth-hook';

import { Menu } from './components/Menu';

import { useCardsLazyQuery } from '../../shared/types/generated';
import { CardItem } from './components/CardItem';
import { PixelFacebookEvent } from '../../shared/utils/PixelFacebookEvent';

type ActiveNum = {
  num?: number;
};

export const CardsDashboard: React.FC<ActiveNum> = ({ num = 1 }) => {
  const { user, refetch, subscription } = useAuth();
  const [fetchCards] = useCardsLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (user) {
      fetchCards({
        variables: {
          userId: user?.id as number,
        },
      });
    }
  }, [user, fetchCards]);

  let content = <></>;
  if (subscription?.paymentMethod === 'Open Pay') {
    content = (
      <Box pt={1}>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight={'800'}>Tarjeta de pago</Typography>
            <Box>
              Elija una tarjeta de crédito y la usaremos para pagar su
              suscripción
            </Box>
          </Grid>
          <Grid item xs={12}>
            <CardItem data={user?.card} onSuccessUpdate={() => refetch()} />
          </Grid>
        </Grid>
      </Box>
    );
  } else if (subscription?.paymentMethod === 'PayPal') {
    content = (
      <Box pt={1}>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Box display="flex">
              <img
                alt="icon-Card"
                src={'/paypal.svg'}
                width={20}
                height={20}
                style={{ marginRight: 10 }}
              />

              <Box>Se uso paypal para pagar su {subscription?.name}</Box>
            </Box>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Box>
    );
  } else {
    content = (
      <div>
        Actualmente no cuentas con una suscripción, te invitamos a ser parte de
        la comunidad Premium
      </div>
    );
  }

  return (
    <>
      <PixelFacebookEvent id="cards-dashboard" />
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box>
              <Menu num={num} />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={'1px solid #9CA5AC'}
              pb={'15px'}
            >
              <Typography fontWeight={'800'}>
                Detalles de facturación
              </Typography>
            </Box>
            <Grid item xs={12}>
              {content}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
