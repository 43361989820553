import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { FACEBOOK_APP_ID, FACEBOOK_CALLBACK } from '../../../shared/constans';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import { MainLoader } from '../../../shared/components/MainLoader';
import { PixelFacebookEvent } from '../../../shared/utils/PixelFacebookEvent';

export const FacebookLoginView: React.FC = () => {
  const query = new URLSearchParams(window.location.search);

  const responseFacebook = (userInfo: ReactFacebookLoginInfo): void => {
    console.log('userInfo', userInfo);
  };

  return (
    <>
      <PixelFacebookEvent id="loginfacebook" />
      <FacebookLogin
        appId={FACEBOOK_APP_ID as string}
        callback={responseFacebook}
        fields="name,email,picture"
        redirectUri={`${FACEBOOK_CALLBACK}/callback/facebook`}
        responseType="token"
        state={query.has('state') ? (query.get('state') as string) : ''}
        isMobile
        render={(renderProps) => {
          if (renderProps.isSdkLoaded) {
            setTimeout(() => {
              renderProps.onClick();
            }, 3000);
          }

          return <MainLoader />;
        }}
      />
    </>
  );
};
