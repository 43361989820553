import React, { ChangeEvent, useEffect, useState } from 'react';
import { Typography, Box, Grid, TextField, Button } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { styled } from '@mui/styles';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import {
  Card,
  useUpdatePaymentDefaultMutation,
} from '../../../shared/types/generated';
import InputMask from 'react-input-mask';
import { openDialogAlert } from '../../alert/alert-actions';
import cardValidator from 'card-validator';
import { createOpenPayInstance } from '../../openpay/openpay-utils';
import { useAuth } from '../../auth/auth-hook';

const StyledUpdateButton = styled(Button)({
  position: 'relative',
  justifyContent: 'center!important',
  alignItems: 'center',
  borderRadius: '5px!important',
  height: 45,
  border: '1px solid #FE0000!important',
  width: 180,
  display: 'flex',
  color: '#FE0000!important',
});

const StyledButton = styled(ButtonDefault)({
  borderRadius: '5px!important',
  backgroundColor: '#FE0000!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#FFCCCC!important',
  },
  height: 45,
});

type CardItemProps = {
  data?: Card | null;
  onSuccessUpdate: () => void;
};

type CreditCardType = {
  cardHolderName: string;
  expiredDate: string;
  cvc: string;
  creditCardNumber: string;
};

export const CardItem: React.FC<CardItemProps> = ({
  data,
  onSuccessUpdate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { subscription } = useAuth();
  const [creditCard, setCreditCard] = useState<CreditCardType>({
    cardHolderName: '',
    expiredDate: '',
    cvc: '',
    creditCardNumber: '',
  });

  useEffect(() => {
    createOpenPayInstance();
  }, []);

  const [UpdatePaymentDefault] = useUpdatePaymentDefaultMutation({
    onCompleted: () => {
      setIsOpen(false);
      setLoading(false);
      onSuccessUpdate();
    },
  });

  const onChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setCreditCard({
      ...creditCard,
      [event.target.name as keyof CreditCardType]: event.target.value as string,
    });
  };

  const onHandlePayment = async (): Promise<void> => {
    setLoading(true);

    const checkCreditCardField = Object.keys(creditCard).find(
      (_creditCard) => creditCard[_creditCard as keyof CreditCardType] === '',
    );

    if (checkCreditCardField) {
      setLoading(false);
      return openDialogAlert('Todos los campos de la tarjeta son requeridos');
    }

    if (!cardValidator.number(creditCard.creditCardNumber).isValid) {
      setLoading(false);
      return openDialogAlert('El numero de tarjeta es incorrecto');
    }

    if (!cardValidator.cvv(creditCard.cvc).isValid) {
      setLoading(false);
      return openDialogAlert('El CVV es incorrecto');
    }

    if (!cardValidator.expirationDate(creditCard.expiredDate).isValid) {
      setLoading(false);

      return openDialogAlert('La fecha de expiracion es incorrecta');
    }

    const date = cardValidator.expirationDate(creditCard.expiredDate);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const deviceDataId = OpenPay.deviceData.setup();
    const sanitizeCreditCard = {
      creditCardNumber: (creditCard.creditCardNumber as string).replaceAll(
        ' ',
        '',
      ),
      cardHolderName: creditCard.cardHolderName as string,
      cvv: creditCard.cvc as string,
      expirationMonth: date.month as string,
      expirationYear: date.year as string,
      deviceDataId,
    };

    UpdatePaymentDefault({
      variables: {
        data: {
          ...sanitizeCreditCard,
        },
      },
    });
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        border={'1px solid #9CA5AC'}
        borderRadius={isOpen ? '5px 5px 0 0' : '5px'}
        p={'10px'}
      >
        <Box display={'flex'} alignItems={'center'} color={'#707070'}>
          <Box display={'flex'}>
            <img
              alt="icon-Card"
              src={'/card-icon.svg'}
              width={20}
              height={20}
            />
          </Box>
          <Box ml={'15px'}>
            <Typography fontWeight={800}>
              {data?.brand}{' '}
              {data?.creditCardNumber?.replaceAll('X', '*').split(' ', 4)}
            </Typography>
            <Box display={'flex'}>
              <AccessTimeIcon fontSize="small" />
              <Typography ml={'5px'} fontSize={14}>
                Vence el {data?.expiredMonth}/{data?.expiredYear}
              </Typography>
            </Box>
          </Box>
        </Box>
        {subscription?.status === 'ACTIVE' && (
          <Box>
            <StyledUpdateButton
              variant="outlined"
              size="large"
              onClick={() => setIsOpen(!isOpen)}
            >
              {!isOpen ? 'Actualizar' : 'Cerrar'}
            </StyledUpdateButton>
          </Box>
        )}
      </Box>
      {isOpen ? (
        <Box
          borderLeft={'1px solid #9CA5AC'}
          borderRight={'1px solid #9CA5AC'}
          borderBottom={'1px solid #9CA5AC'}
          borderRadius="0 0 5px 5px"
          p={'10px'}
        >
          <Grid container spacing={2}>
            <Grid item md={12}>
              <TextField
                label={'Nombre del titular'}
                name="cardHolderName"
                fullWidth
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => onChangeHandler(event)}
              />
            </Grid>
            <Grid item md={12}>
              <InputMask
                mask="9999 9999 9999 9999"
                maskChar=" "
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                maskPlaceholder={null}
                onChange={(event) => onChangeHandler(event)}
              >
                <TextField
                  label={'Número de tarjeta'}
                  name="creditCardNumber"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </InputMask>
            </Grid>

            <Grid item md={6}>
              <InputMask
                mask="99/99"
                maskChar=" "
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                maskPlaceholder={null}
                onChange={(event) => onChangeHandler(event)}
              >
                <TextField
                  label={'Fecha de vencimiento'}
                  name="expiredDate"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </InputMask>
            </Grid>
            <Grid item md={6}>
              <InputMask
                mask="999"
                maskChar=" "
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                maskPlaceholder={null}
                onChange={(event) => onChangeHandler(event)}
              >
                <TextField
                  label={'CVV/CVC'}
                  name="cvc"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </InputMask>
            </Grid>
            <Grid item md={12}>
              <StyledButton
                variant="contained"
                size="large"
                color="secondary"
                fullWidth
                isLoading={loading}
                onClick={() => onHandlePayment()}
              >
                Guardar
              </StyledButton>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </>
  );
};
