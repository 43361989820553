import Bugsnag from '@bugsnag/js';
import { BUGSNAG_API_KEY } from '../../shared/constans';

export const bugsnagStart = (): void => {
  console.log(BUGSNAG_API_KEY);
  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    plugins: [],
    autoTrackSessions: false,
    releaseStage: 'production',
  });
};
