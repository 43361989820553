import React, { useEffect } from 'react';

export enum HTMLPositionEnum {
  Head = 'HEAD',
  Body = 'BODY',
  Footer = 'FOOTER',
}

type UseScriptProps = {
  src?: string;
  delay?: number;
  scriptContent?: string;
  scriptId?: string;
  position?: HTMLPositionEnum;
  isLoading?: boolean;
  async?: boolean;
};
export const useScript = ({
  src = '',
  delay = 100,
  scriptContent = '',
  position = HTMLPositionEnum.Head,
  scriptId = '',
  isLoading = true,
  async = false,
}: UseScriptProps): string => {
  const [status, setStatus] = React.useState(src !== '' ? 'loading' : 'idle');
  useEffect(() => {
    let script: HTMLScriptElement | null = document.querySelector(
      `script[src="${src}"]`,
    );

    //console.log('ejecuto', scriptId);
    let timeout: null | ReturnType<typeof setTimeout> = null;
    const setStateStatus = (event: Event): null => {
      setStatus(event.type === 'load' ? 'ready' : 'error');
      return null;
    };

    if (!script && isLoading) {
      if (delay) {
        timeout = setTimeout(() => {
          injectScript();

          // Add event listener after the script is added
          if (script) {
            script.addEventListener('load', setStateStatus);
            script.addEventListener('error', setStateStatus);
          }
        }, delay);
      }
    } else {
      // Grab existing script status from attribute and set to state.
      setStatus(script?.getAttribute('data-status') ?? '');
    }

    //code to inject script
    function injectScript(): null {
      script = document.createElement('script');
      if (src) {
        script.src = src as string;
      }
      if (scriptId) {
        script.id = scriptId;
      }
      if (async) {
        script.async = true;
      }

      if (src) {
        script.setAttribute('data-status', 'loading');
      }
      script.appendChild(document.createTextNode(scriptContent));
      if (position === HTMLPositionEnum.Head) document.head.appendChild(script);
      else document.body.appendChild(script);
      const setDataStatus = (event: Event): null => {
        if (script) {
          script.setAttribute(
            'data-status',
            event.type === 'load' ? 'ready' : 'error',
          );
        }
        return null;
      };

      script.addEventListener('load', setDataStatus);
      script.addEventListener('error', setDataStatus);
      return null;
    }

    /**
     * if (script) {
      //script will be be undefined available when its delayed hence check it before adding listener
      script.addEventListener('load', setStateStatus);
      script.addEventListener('error', setStateStatus);
    }
     */

    return () => {
      if (script) {
        script.removeEventListener('load', setStateStatus);
        script.removeEventListener('error', setStateStatus);
      }
      if (timeout) {
        clearTimeout(timeout);
      }
      return;
    };
  }, [delay, position, scriptContent, src, scriptId, isLoading, async]);

  return status;
};

//export default useScript;
