import React from 'react';
import {
  Container,
  Divider,
  Grid,
  Typography,
  Theme,
  Box,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { Menu } from './components/Menu';
import { HistoryItem } from './components/HistoryItem';
import { useFetchNotificationsQuery } from '../../shared/types/generated';
import { useAuth } from '../auth/auth-hook';
import { PixelFacebookEvent } from '../../shared/utils/PixelFacebookEvent';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    divider: {
      borderWidth: 2,
      borderColor: 'black!important',
      marginTop: '15px!important',
      marginBottom: '15px!important',
    },
    button: {
      borderColor: theme.palette.primary.main,
    },
    textRed: {
      color: '#FA0000',
    },
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
    },
  }),
);

type ActiveNum = {
  num?: number;
};

export const History: React.FC<ActiveNum> = ({ num = 1 }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const { data } = useFetchNotificationsQuery({
    variables: {
      userId: user?.id,
    },
  });
  const notifications = data?.notifications?.data || [];
  return (
    <>
      <PixelFacebookEvent id="history" />
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box>
              <Menu num={num} />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box mt={'13px'}>
              <Typography variant="body2" style={{ fontSize: '16px' }}>
                Acá encontraras informacion detallada del historial de
                notificaciones.
              </Typography>
              <Divider className={classes.divider} />
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={'1px solid #9CA5AC'}
              pb={'15px'}
            >
              <Typography fontWeight={'800'}>
                Historial ({notifications?.length})
              </Typography>
            </Box>
            <Grid container>
              <Grid item xs={12}>
                <Box>
                  {notifications.map((notification, i) => (
                    <HistoryItem notification={notification} key={i} />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
