import React, { useEffect } from 'react';
import {
  Container,
  Divider,
  Grid,
  Typography,
  Theme,
  Box,
  Skeleton,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { useAuth } from '../auth/auth-hook';

import { Menu } from './components/Menu';
import { TransactionItem } from './components/TransactionItem';
import { Invoice, useInvoicesLazyQuery } from '../../shared/types/generated';
import { PixelFacebookEvent } from '../../shared/utils/PixelFacebookEvent';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    divider: {
      borderWidth: 2,
      borderColor: 'black!important',
      marginTop: '15px!important',
      marginBottom: '15px!important',
    },
    button: {
      borderColor: theme.palette.primary.main,
    },
    textRed: {
      color: '#FA0000',
    },
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
    },
  }),
);

type ActiveNum = {
  num?: number;
};

export const Transactions: React.FC<ActiveNum> = ({ num = 1 }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const [fetchInvoices, { data, loading }] = useInvoicesLazyQuery();

  useEffect(() => {
    if (user) {
      fetchInvoices({
        variables: {
          userId: user?.id as number,
        },
      });
    }
  }, [user, fetchInvoices]);

  const invoices = data && data.invoices ? data.invoices : [];

  return (
    <>
      <PixelFacebookEvent id="transactions" />
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box>
              <Menu num={num} />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box mt={'13px'}>
              <Typography variant="body2" style={{ fontSize: '16px' }}>
                Acá encontraras informacion detallada del historial de pagos
              </Typography>
              <Divider className={classes.divider} />
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={'1px solid #9CA5AC'}
              pb={'15px'}
            >
              <Typography fontWeight={'800'}>
                Transacciones ({invoices.length})
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={'1px solid #9CA5AC'}
              py={'15px'}
            >
              <Grid container>
                <Grid item xs={4} lg={3}>
                  <Box display={'flex'} color={'#9CA5AC'}>
                    <Typography mr={'20px'}>Fecha de compra</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} lg={7}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        color={'#9CA5AC'}
                      >
                        <Typography>Recurso</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        color={'#9CA5AC'}
                      >
                        <Typography>Estatus</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    color={'#9CA5AC'}
                  >
                    <Typography display={'flex'}>Monto</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <>
              {loading ? (
                <>
                  {[1, 2, 3].map((item) => (
                    <Grid container key={item}>
                      <Grid item xs={3} style={{ padding: 10 }}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={10}
                        />
                      </Grid>
                      <Grid item xs={3} style={{ padding: 10 }}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={10}
                        />
                      </Grid>
                      <Grid item xs={3} style={{ padding: 10 }}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={10}
                        />
                      </Grid>
                      <Grid item xs={3} style={{ padding: 10 }}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={10}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </>
              ) : (
                <Grid container>
                  <Grid item xs={12}>
                    <Box>
                      {invoices.map((item, i) => (
                        <TransactionItem data={item as Invoice} key={i} />
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              )}
            </>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
