import { createEvent } from '@cobuildlab/react-simple-state';
import { User } from '../../shared/types/generated';

export const userUpdateEvent = createEvent<{ user: User | undefined }>({
  initialValue: {
    user: undefined,
  },
});

export const userUpdateErrorEvent = createEvent<Error>();
