import React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import PowerSettingsNewSharpIcon from '@mui/icons-material/PowerSettingsNewSharp';
import { useAuth } from '../../../modules/auth/auth-hook';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { openMobileMenu } from '../../../modules/dashboard/dashboard-actions';
import { useStore } from '@cobuildlab/react-simple-state';
import { openMobileMenuEvent } from '../../../modules/dashboard/dashboard-events';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    toolbar: {
      backgroundColor: 'white',
      boxShadow: 'none',
      color: 'black',
    },
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
      color: '#000!important',

      margin: '0 0',
    },
    menu: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      color: 'white!important',
      marginRight: '18px!important',
      marginLeft: '5px!important',
    },
    textRed: {
      color: '#FE0000',
    },
    groupButton: {
      borderBottom: '1px solid #999999!important',
      borderTop: '1px solid #CCCCCC!important',
      padding: '10px 0px 10px 0px',
      display: 'block!important',
    },
  }),
);

export const Footer: React.FC = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        component={'div'}
        style={{
          backgroundColor: 'white !important',
          width: '100%',
        }}
      >
        <Box
          justifyContent={'center'}
          alignItems={'center'}
          display={'flex'}
          width={'100%'}
          component={'div'}
          style={{ height: '80px', background: 'white' }}
        >
          <Box>
            <Typography fontSize={16} color={'#999999'} textAlign={'center'}>
              Conoce más de nuestros
            </Typography>
            <Box
              display={'flex'}
              justifyContent={'center'}
              justifyItems={'center'}
            >
              <Box
                style={{
                  color: '#777777',
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                target="_blank"
                component="a"
                href="https://ac.noroeste.com.mx/autoayuda"
                fontWeight="bold"
                fontSize={'16px'}
              >
                {'Términos de uso'}
              </Box>

              <Box fontSize={16} component="span" mr={1} ml={1} color="#777777">
                {' y '}
              </Box>
              <Box
                style={{
                  color: '#777777',
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                target="_blank"
                component="a"
                href="https://www.noroeste.com.mx/privacidad"
                fontWeight="bold"
                fontSize={'16px'}
              >
                {'Política de Privacidad'}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
