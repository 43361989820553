import React from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  Hidden,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { useAuth } from '../auth/auth-hook';

import { Menu } from './components/Menu';
import { LibraryItem } from './components/LibraryItem';
import { PixelFacebookEvent } from '../../shared/utils/PixelFacebookEvent';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    divider: {
      borderWidth: 2,
      borderColor: 'black!important',
      marginTop: '15px!important',
      marginBottom: '15px!important',
    },
    button: {
      borderColor: theme.palette.primary.main,
    },
    textRed: {
      color: '#FA0000',
    },
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
    },
  }),
);
type ActiveNum = {
  num?: number;
};

export const Library: React.FC<ActiveNum> = ({ num = 1 }) => {
  const classes = useStyles();
  const { user } = useAuth();

  return (
    <>
      <PixelFacebookEvent id="library" />
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box>
              <Menu num={num} />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box mt={'13px'}>
              <Typography variant="body2" style={{ fontSize: '16px' }}>
                Acá encontraras informacion detallada de las suscripciones
              </Typography>
              <Divider className={classes.divider} />
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={'1px solid #9CA5AC'}
              pb={'15px'}
            >
              <Typography fontWeight={'600'}>
                Mis suscripciones (
                {user?.subscriptions ? user?.subscriptions?.length : 0})
              </Typography>
            </Box>
            <Grid
              container
              paddingTop={'10px'}
              style={{ borderBottom: '1px solid #CCD0D4', paddingBottom: 10 }}
            >
              <Grid item xs={3} md={3}>
                <Box display={'flex'} width={'100%'} color={'#9CA5AC'}>
                  <Typography mr={'50px'}>Fecha de Inicio</Typography>
                </Box>
              </Grid>

              <Grid item xs={3} md={3}>
                <Box display={'flex'} width={'100%'} color={'#9CA5AC'}>
                  <Typography mr={'50px'}>Fecha de Renovación</Typography>
                </Box>
              </Grid>
              <Grid item xs={3} md={3}>
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  color={'#9CA5AC'}
                >
                  <Typography>Recurso</Typography>
                </Box>
              </Grid>
              <Grid item xs={3} md={3}>
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  color={'#9CA5AC'}
                >
                  <Typography>Estatus</Typography>
                </Box>
              </Grid>
              <Hidden mdUp={false} mdDown={true}>
                <Grid item xs={3}>
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    color={'#9CA5AC'}
                  ></Box>
                </Grid>
              </Hidden>
            </Grid>
            <Box>
              <Box display={'relative'}>
                {user?.subscriptions?.map((item, i) => (
                  <LibraryItem item={item} key={i} />
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
