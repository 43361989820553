import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { NewLayout } from '../../shared/components/layout/NewLayout';
import {
  AUTH_EMAIL,
  AUTH_LOGIN,
  AUTH_REGISTER,
  AUTH_HOME,
  AUTH_RESET_PASSWORD,
  AUTH_RESET_PASSWORD_DONE,
  AUTH_FORGOT_PASSWORD,
  AUTH_FORGOT_PASSWORD_NOT_FOUND,
  AUTH_PASSWORD_EMAIL_SEND,
  DOMAIN_SITE,
  APP_ENV,
} from '../../shared/constans';
import { AuthLoginForm } from './components/AuthLoginForm';
import { AuthEmailForm } from './components/AuthEmailForm';
import { styled } from '@mui/styles';
import { AuthRegisterForm } from './components/AuthRegisterForm';
import { GoogleButton } from './components/GoogleButton';
import { FacebookButton } from './components/FacebookButton';
import { AppleButton } from './components/AppleButton';
import {
  useCheckUserWithRecaptchaMutationMutation,
  useSignUpWithSocialProviderMutation,
  useSigUpMutationMutation,
} from '../../shared/types/generated';
import { AuthResetPasswordForm } from './components/AuthResetPasswordForm';
import { AuthForgotPassword } from './components/AuthForgotPassword';
import { AuthForgotPasswordNotFound } from './components/AuthForgotPasswordNotFound';
import { AuthChangePasswordEmail } from './components/AuthChangePasswordEmail';
import { AuthPasswordHasChange } from './components/AuthPasswordHasChange';
import { useCookies } from 'react-cookie';
import { useEvent, useStore } from '@cobuildlab/react-simple-state';
import { changeIsLoginStore, registerUserDataEvent } from './auth-events';
import {
  getRedirectUrl,
  getResetPasswordToken,
  getSuccessUrl,
} from './auth-utils';
import moment from 'moment';
import { changeLoginAction } from './auth-actions';
import { openDialogAlert } from '../alert/alert-actions';
import { PixelFacebookEvent } from '../../shared/utils/PixelFacebookEvent';

const StyledBox = styled(Box)({
  cursor: 'pointer',
  color: '#FE0000',
});
const StyledButton = styled(Box)({
  cursor: 'pointer',
  fontSize: '19px',
  boxShadow: '0px 0px 2px #1717171A',
  borderRadius: '3px',
  width: '114px',
  height: '29px',
});

export const Auth: React.FC = () => {
  const query = new URLSearchParams(window.location.search);
  const [, setCookie] = useCookies(['_u', 'loginWithSocial']);
  const isInStandaloneMode = query.has('isInStandaloneMode')
    ? (query.get('isInStandaloneMode') as string)
    : false;
  const [loginWithSocial, setLoginWithSocial] = useState<string | undefined>(
    undefined,
  );

  const { email } = useEvent(registerUserDataEvent);

  const { isLogin } = useStore(changeIsLoginStore);

  const [passwordResetToken, setPasswordResetToken] = useState(
    getResetPasswordToken(),
  );

  const [step, setStep] = useState<string>(
    query.has('step') ? (query.get('step') as string) : AUTH_HOME,
  );
  console.log(getSuccessUrl());
  const [signUpMutation] = useSigUpMutationMutation({
    onCompleted: ({ SignUpMutation }) => {
      if (SignUpMutation?.access_token) {
        setCookie('_u', SignUpMutation?.access_token, {
          path: '/',
          domain: DOMAIN_SITE,
          expires: moment().add(1, 'year').toDate(),
        });
        console.log(getSuccessUrl());

        window.location.href = getSuccessUrl() || getRedirectUrl();
      }
    },
    onError: () => {},
  });

  const [signUpWithSocialProviderMutation] =
    useSignUpWithSocialProviderMutation({
      onCompleted: ({ SignUpWithSocialProviderMutation }) => {
        if (SignUpWithSocialProviderMutation?.access_token) {
          setCookie('loginWithSocial', loginWithSocial, {
            path: '/',
            domain: DOMAIN_SITE,
            expires: moment().add(1, 'year').toDate(),
          });
          setCookie('_u', SignUpWithSocialProviderMutation?.access_token, {
            path: '/',
            domain: DOMAIN_SITE,
            expires: moment().add(1, 'year').toDate(),
          });

          console.log(getSuccessUrl());

          window.location.href = getSuccessUrl() || getRedirectUrl();
        }
      },
    });

  const [checkUserWithRecaptchaMutation] =
    useCheckUserWithRecaptchaMutationMutation({
      onCompleted: ({ CheckUserWithRecaptchaMutation }) => {
        if (
          CheckUserWithRecaptchaMutation?.success &&
          CheckUserWithRecaptchaMutation?.withPassword
        ) {
          return;
        } else if (
          CheckUserWithRecaptchaMutation?.success &&
          !CheckUserWithRecaptchaMutation?.withPassword &&
          CheckUserWithRecaptchaMutation?.passwordResetToken
        ) {
          setPasswordResetToken(
            CheckUserWithRecaptchaMutation?.passwordResetToken,
          );
          setStep(AUTH_RESET_PASSWORD);
        } else {
          changeLoginAction(false);
          setStep(AUTH_HOME);
          openDialogAlert(
            'No se encontró una cuenta relacionada al Email, puede registrarse a continuación',
          );
        }
      },
      onError: () => {
        console.log('');
      },
    });

  let content = <></>;
  useEffect(() => {
    if (step === AUTH_REGISTER) {
      changeLoginAction(false);
      setStep(AUTH_HOME);
    }
  }, [step]);
  switch (step) {
    case AUTH_LOGIN:
      content = (
        <AuthLoginForm
          //onChangeEmail={() => setStep(AUTH_EMAIL)}
          onSuccess={(accessToken) => {
            setCookie('_u', accessToken, {
              path: '/',
              domain: DOMAIN_SITE,
              expires: moment().add(1, 'year').toDate(),
            });
            window.location.href = getRedirectUrl();
          }}
        />
      );
      break;
    /**
    *  case AUTH_REGISTER:
      content = (
        <AuthRegisterForm
          onSuccess={(data) => {
            changeLoginAction(true);

            signUpMutation({
              variables: {
                data: {
                  email: data.email as string,
                  password: data.password as string,
                },
              },
            });
          }}
        />
      );
      break;
    */
    case AUTH_FORGOT_PASSWORD_NOT_FOUND:
      content = (
        <AuthForgotPasswordNotFound onBack={() => setStep(AUTH_HOME)} />
      );
      break;
    case AUTH_RESET_PASSWORD:
      content = (
        <AuthResetPasswordForm
          passwordResetToken={passwordResetToken}
          onSuccess={(data) => {
            signUpMutation({
              variables: {
                data: {
                  email: data.email as string,
                  password: data.password as string,
                },
              },
            });
            setStep(AUTH_RESET_PASSWORD_DONE);
          }}
        />
      );
      break;
    case AUTH_RESET_PASSWORD_DONE:
      content = <AuthPasswordHasChange onBack={() => setStep(AUTH_LOGIN)} />;

      break;
    case AUTH_FORGOT_PASSWORD:
      content = (
        <AuthForgotPassword
          onBack={() => {
            setStep(AUTH_HOME);
          }}
          onSuccess={() => {
            setStep(AUTH_PASSWORD_EMAIL_SEND);
          }}
        />
      );
      break;
    case AUTH_PASSWORD_EMAIL_SEND:
      content = (
        <AuthChangePasswordEmail
          onBack={() => {
            setStep(AUTH_HOME);
          }}
          onTimePass={() => {
            setStep(AUTH_HOME);
          }}
        />
      );
      break;

    default:
      content = (
        <>
          <Grid item xs={12}>
            <Box mb={1}>
              <Grid container>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Box fontSize={18} textAlign="center">
                    <Box
                      component={'div'}
                      style={{
                        background: '#EBEBEB',
                        padding: '2px',
                        borderRadius: '3px',
                      }}
                      display={'inline-flex'}
                      margin={'0 auto'}
                    >
                      <StyledButton
                        component="div"
                        onClick={() => changeLoginAction(true)}
                        style={{
                          color: `${isLogin ? '#000' : '#8A8A8A'}`,
                          background: `${isLogin ? '#FFFFFF' : '#EBEBEB'}`,
                        }}
                      >
                        {'Acceso'}
                      </StyledButton>
                      <StyledButton
                        component="div"
                        onClick={() => {
                          changeLoginAction(false);
                        }}
                        style={{
                          color: `${!isLogin ? '#000' : '#8A8A8A'}`,
                          background: `${!isLogin ? '#FFFFFF' : '#EBEBEB'}`,
                        }}
                      >
                        {'Registro'}
                      </StyledButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              {isLogin ? (
                <>
                  <AuthLoginForm
                    onSuccess={(accessToken) => {
                      setCookie('_u', accessToken, {
                        path: '/',
                        domain: DOMAIN_SITE,
                        expires: moment().add(1, 'year').toDate(),
                      });
                      window.location.href = getRedirectUrl();
                    }}
                    onTestEmail={() => {
                      checkUserWithRecaptchaMutation({
                        variables: {
                          data: {
                            email,
                          },
                        },
                      });
                    }}
                  />
                  <Grid item xs={12}>
                    {
                      <StyledBox
                        color="#FE0000"
                        textAlign="center"
                        fontSize={18}
                        onClick={() => {
                          setStep(AUTH_FORGOT_PASSWORD);
                        }}
                      >
                        ¿Olvidó su contraseña?
                      </StyledBox>
                    }
                  </Grid>
                </>
              ) : (
                <AuthRegisterForm
                  onSuccess={(data) => {
                    changeLoginAction(true);

                    signUpMutation({
                      variables: {
                        data: {
                          email: data.email as string,
                          password: data.password as string,
                        },
                      },
                    });
                  }}
                />
              )}
            </Box>
          </Grid>
          <Grid item paddingTop={2} xs={12}>
            <Divider style={{ fontSize: '12px', paddingTop: 2 }}>O</Divider>
            <Typography
              paddingY={2}
              textAlign={'center'}
              fontSize={'14px'}
              color={'#555555'}
            >
              Puedes hacerlo utilizando tu red social favorita
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={
                !navigator.userAgent.match(/FBAN|FBAV/i) && !isInStandaloneMode
                  ? 2
                  : 1
              }
            >
              {!navigator.userAgent.match(/FBAN|FBAV/i) &&
              !isInStandaloneMode ? (
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <GoogleButton
                      onSuccess={(_email) => {
                        signUpWithSocialProviderMutation({
                          variables: {
                            data: {
                              email: _email,
                              provider: 'GOOGLE',
                            },
                          },
                        });
                        setLoginWithSocial('Google');
                      }}
                    />
                  </Box>
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <Box textAlign="center">
                  <FacebookButton
                    onSuccess={(_email) => {
                      signUpWithSocialProviderMutation({
                        variables: {
                          data: {
                            email: _email,
                            provider: 'FACEBOOK',
                          },
                        },
                      });
                      setLoginWithSocial('Facebook');
                    }}
                  />
                </Box>
              </Grid>

              {!navigator.userAgent.match(/FBAN|FBAV/i) &&
              !isInStandaloneMode &&
              APP_ENV !== 'local' ? (
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <AppleButton
                      onSuccess={(_email) => {
                        signUpWithSocialProviderMutation({
                          variables: {
                            data: {
                              email: _email,
                              provider: 'APPLE',
                            },
                          },
                        });
                        setLoginWithSocial('Apple');
                      }}
                    />
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} p={0}></Grid>
        </>
      );
      break;
  }

  return (
    <>
      <PixelFacebookEvent id="Auth" />
      <NewLayout>
        <Box
          paddingTop={14}
          component={'div'}
          style={{ background: '#F3F4F6' }}
          alignItems="center"
          display="flex"
          paddingBottom={4}
        >
          <Grid container justifyContent="center">
            <Grid item xs={12} md={4}>
              <Grid container style={{ width: '324px', margin: '0 auto' }}>
                {content}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </NewLayout>
    </>
  );
};
