import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Theme,
} from '@mui/material';
import { closeDialogAlert } from '../../../modules/alert/alert-actions';
import { createStyles, makeStyles, styled } from '@mui/styles';
import { ButtonDefault } from '../ButtonDefault';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    closeButton: {
      background: '#FA0000!important',
      color: 'white!important',
      width: '180px',
    },
    buttonDisconnect: {
      background: '!whiteimportant',
      color: '#FA0000!important',
      border: '1px solid #FA0000',
      width: '180px',
    },
    textSocial: {
      margin: '0px 0px 0px 15px!important',
    },
    dialogContentText: {
      fontSize: 20,
    },
    positionCenter: {
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center',
    },
    defaultAlertBox: {
      maxWidth: '300px',
      margin: '0 auto',
      textAlign: 'center',
    },
  }),
);

const StyledButton = styled(Button)({
  position: 'relative',
  justifyContent: 'center!important',
  borderRadius: '5px!important',
  height: 45,
  border: '1px solid #FFCCCC!important',
  width: '100%',
  maxWidth: 180,
});

const StyledAddButton = styled(ButtonDefault)({
  borderRadius: '5px!important',
  backgroundColor: '#FE0000!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#FFCCCC!important',
  },
  width: '100%',
  maxWidth: 180,
  height: 45,
});

type DefaultDialogProps = {
  isOpen?: boolean;
  isLoading?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
  children?: React.ReactNode;
  textSuccessButton?: string;
  textCancelButton?: string;
};
export const DefaultDialog: React.FC<DefaultDialogProps> = ({
  isOpen = false,
  onCancel,
  onSuccess,
  isLoading,
  children,
  textSuccessButton = 'Confirmar',
  textCancelButton = 'Cancelar',
}) => {
  const classes = useStyles();

  return (
    <Dialog onClose={() => closeDialogAlert()} open={isOpen}>
      <DialogContent className={classes.defaultAlertBox}>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.dialogContentText}
        >
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.positionCenter}>
        <StyledButton onClick={() => onCancel && onCancel()} variant="outlined">
          {textCancelButton}
        </StyledButton>
        <StyledAddButton
          variant="contained"
          size="large"
          isLoading={isLoading}
          onClick={() => onSuccess && onSuccess()}
        >
          {textSuccessButton}
        </StyledAddButton>
      </DialogActions>
    </Dialog>
  );
};
