import React, { useEffect, useState } from 'react';
import {
  PayPalButtons,
  PayPalScriptProvider,
  FUNDING,
} from '@paypal/react-paypal-js';
import {
  ANUAL_PLAN,
  BASIC_PLAN,
  PAYPAL_CLIENT_ID,
  SEMESTRAL_PLAN,
} from '../../shared/constans';
import { PaymentTime } from '../openpay/openpay-constants';
import { useAuth } from '../auth/auth-hook';
import { useCreatePaypalOrderMutation } from '../../shared/types/generated';
import { openDialogAlert } from '../alert/alert-actions';

type PaypalButtonProps = {
  frequency?: PaymentTime;
};

const PAYPAL_PLANS: { frequency: PaymentTime; planId?: string }[] = [
  {
    frequency: PaymentTime.Daily,
    planId: BASIC_PLAN,
  },
  {
    frequency: PaymentTime.Monthly,
    planId: SEMESTRAL_PLAN,
  },
  {
    frequency: PaymentTime.Annually,
    planId: ANUAL_PLAN,
  },
];

type ButtonWrapperProps = {
  frequency: PaymentTime;
};

const ButtonWrapper: React.FC<ButtonWrapperProps> = ({ frequency }) => {
  const { user } = useAuth();
  const [plan, setPlan] = useState<
    { frequency: PaymentTime; planId?: string } | undefined
  >(undefined);

  const [createPayPalOrder] = useCreatePaypalOrderMutation({
    onCompleted: (data) => {
      if (data.createPaypalOrder?.success) {
        return (window.location.href =
          'https://www.noroeste.com.mx/premium-completado');
      } else {
        return openDialogAlert(data.createPaypalOrder?.message as string);
      }
    },
    onError: () => {
      return openDialogAlert('Tenemos problemas para realizar la suscripción');
    },
  });

  useEffect(() => {
    if (frequency) {
      const paypalPlan = PAYPAL_PLANS.find(
        (_plan) => _plan.frequency === frequency,
      );
      setPlan(paypalPlan);
    }
  }, [frequency]);

  return user ? (
    <PayPalButtons
      fundingSource={FUNDING.PAYPAL}
      createSubscription={async (data, actions) => {
        return actions.subscription
          .create({
            plan_id: plan?.planId as string,
            subscriber: {
              email_address: user?.email,
            },
            custom_id: user?.email,
          })
          .then((orderId) => {
            return orderId;
          });
      }}
      onApprove={async (data) => {
        {
          const { subscriptionID, orderID } = data;
          console.log('user', user);
          createPayPalOrder({
            variables: {
              data: {
                orderId: orderID,
                subscriptionId: subscriptionID,
                email: user?.email,
                frequency,
              },
            },
          });
        }
      }}
      style={{
        label: 'subscribe',
      }}
      onError={() => {
        return openDialogAlert(
          'Tenemos problemas para realizar la suscripción',
        );
      }}
    />
  ) : null;
};

export const PaypalButton: React.FC<PaypalButtonProps> = ({
  frequency = undefined,
}) => {
  return (
    <PayPalScriptProvider
      options={{
        clientId: PAYPAL_CLIENT_ID as string,
        components: 'buttons',
        intent: 'subscription',
        vault: true,
      }}
    >
      <ButtonWrapper frequency={frequency as PaymentTime} />
    </PayPalScriptProvider>
  );
};
