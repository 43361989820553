import React, { useEffect, useState } from 'react';
import { Provider } from './AuthContext';
import {
  Subscription,
  useFetchUserLazyQuery,
  User,
} from '../../shared/types/generated';
import { ApolloError } from '@apollo/client/errors';
import { useCookies } from 'react-cookie';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { fetchToken } from './auth-actions';
import moment from 'moment';

type AuthProviderProps = {
  children?: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [cookies, , removeCookie] = useCookies(['_u', 'loginWithSocial']);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
  const [user, setUser] = useState<User | undefined>(undefined);

  const [socialProvider, setSocialProvider] = useState<string | undefined>(
    undefined,
  );
  const [subscription, setSubscription] = useState<
    Subscription | undefined | null
  >(undefined);

  const [error, setError] = useState<ApolloError | Error | undefined>(
    undefined,
  );

  const [fetchUser] = useFetchUserLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setIsLoading(false);

      if (data.user) {
        setSubscription(
          data.user?.subscriptions?.find((_subscription) => {
            const startDate = moment(_subscription?.startDate).format(
              'YYYY/MM/DD',
            );
            const endDate = moment(_subscription?.endDate).format('YYYY/MM/DD');
            const compareDate = moment(new Date(), 'YYYY/MM/DD');
            return (
              _subscription?.status === 'ACTIVE' ||
              compareDate.isBetween(startDate, endDate)
            );
          }),
        );
        setUser(data.user as User);
        setIsAuthenticated(true);
        return;
      } else {
        removeCookie('_u');
      }
    },
    onError: (data) => {
      setError(data);
      removeCookie('_u');
    },
  });

  const [callAction] = useCallAction(fetchToken, {
    onCompleted: ({ token }) => token && fetchUser(),
    onError: (data) => {
      setError(data);
    },
  });
  useEffect(() => {
    callAction(cookies._u);
  }, [callAction, cookies._u]);

  useEffect(() => {
    if (cookies.loginWithSocial) {
      setSocialProvider(cookies.loginWithSocial);
    }
  }, [cookies.loginWithSocial]);

  const refetch = (): void => {
    console.log('refetch');
    fetchUser();
  };

  const handleSocialProvider = (userSocialProvider: string): void => {
    setSocialProvider(userSocialProvider);
  };

  const values = {
    isAuthenticated,
    isLoading,
    user,
    subscription,
    error,
    refetch,
    socialProvider,
    handleSocialProvider,
  };

  return <Provider value={values}>{children}</Provider>;
};
