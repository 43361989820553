import React, { useEffect, useState } from 'react';
import { Button, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useAuth } from '../../auth/auth-hook';
import { useGoogleLogin } from '@react-oauth/google';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { fetchUserGoogle } from '../../auth/auth-actions';
import { GoogleUserType } from '../../auth/auth-types';
import {
  useCreateUserProviderMutation,
  useDeleteUserSocialProviderMutation,
} from '../../../shared/types/generated';
import { getCurrentUrl } from '../../auth/auth-utils';
import { openDialogAlert } from '../../alert/alert-actions';
import { DefaultDialog } from '../../../shared/components/dialog/DefaultDialog';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    buttonConnect: {
      background: '#FA0000!important',
      color: 'white!important',
      width: '180px',
      height: 45,
    },
    buttonDisconnect: {
      background: '!whiteimportant',
      color: '#FA0000!important',
      border: '1px solid #FA0000',
      width: '180px',
      height: 45,
    },
    textSocial: {
      margin: '0px 0px 0px 15px!important',
    },
    disable: {
      background: '#efefef!important',
      opacity: '65% !important',
    },
  }),
);

type GoogleConnectButtonProps = {
  isConnect?: boolean;
  isActive?: boolean;
};
export const GoogleConnectButton: React.FC<GoogleConnectButtonProps> = ({
  isConnect = false,
  isActive = true,
}) => {
  const classes = useStyles();
  const { user, refetch } = useAuth();
  const [googleUser, setGoogleUser] = useState<GoogleUserType | undefined>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [createGoogleProvider] = useCreateUserProviderMutation({
    onCompleted: () => {
      setGoogleUser(undefined);
      refetch();
      return openDialogAlert(
        'Se ha conectado tu cuenta de Google correctamente',
      );
    },
  });

  const [deleteGoogleProvider, { loading: loadingDelete }] =
    useDeleteUserSocialProviderMutation({
      onCompleted: () => {
        setIsOpen(false);
        refetch();
      },
    });

  const [googleUserCallback] = useCallAction(fetchUserGoogle, {
    onCompleted: (data) => setGoogleUser(data),
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      googleUserCallback(tokenResponse);
    },
    onError: (error) => console.log('Login Failed:', error),
  });

  useEffect(() => {
    if (googleUser) {
      createGoogleProvider({
        variables: {
          data: {
            provider: 'GOOGLE',
            providerId: googleUser.email,
            userId: user?.id as number,
            urlRegister: getCurrentUrl(),
          },
        },
      });
    }
  }, [googleUser, user, createGoogleProvider]);

  return isConnect ? (
    <>
      <Button
        className={classes.buttonDisconnect}
        classes={{ disabled: classes.disable }}
        onClick={() => setIsOpen(true)}
        disabled={!isActive}
      >
        Desconectar
      </Button>
      <DefaultDialog
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        isLoading={loadingDelete}
        onSuccess={() =>
          deleteGoogleProvider({
            variables: {
              data: {
                userId: user?.id,
                provider: 'GOOGLE',
              },
            },
          })
        }
      >
        <div style={{ fontSize: 18, padding: '20px 0' }}>
          ¿Estas seguro que deseas desconectar tu cuenta de Google?
        </div>
      </DefaultDialog>
    </>
  ) : (
    <Button className={classes.buttonConnect} onClick={() => googleLogin()}>
      Conectar
    </Button>
  );
};
