import React from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { EmailOutlined } from '@mui/icons-material';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { useUserForgotPasswordMutationMutation } from '../../../shared/types/generated';
import { registerUserDataEvent } from '../auth-events';
import { useEvent } from '@cobuildlab/react-simple-state';
import { getFormType } from '../auth-utils';

const StyledTypographyText = styled(Typography)({
  color: '#999999',
  fontSize: 16,
});

const StyledButton = styled(ButtonDefault)({
  borderRadius: '5px!important',
  backgroundColor: '#FE0000!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#FFCCCC!important',
  },
  width: 200,
});

type AuthForgotPasswordType = {
  onBack: () => void;
  onSuccess: () => void;
};
const StyledBox = styled(Box)({
  cursor: 'pointer',
  marginTop: '15px',
});
const StyledIcon = styled(EmailOutlined)({
  //cursor: 'pointer',
  color: '#999999',
  fontSize: '20px!important',
  paddingRight: '5px',
});
export const AuthForgotPassword: React.FC<AuthForgotPasswordType> = ({
  onBack,
  onSuccess,
}) => {
  const form = getFormType();
  const { email } = useEvent(registerUserDataEvent);

  const [userForgotPassword, { loading }] =
    useUserForgotPasswordMutationMutation({
      onCompleted: () => onSuccess(),
    });

  return (
    <Box>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Box mb={4}>
            {/**
             * <img src="/LOGO_NOROESTE.svg" alt="logo" width={225} height={25} />{' '}
             */}
            <Typography fontSize={'19px'} fontWeight={700}>
              Restaurar contraseña
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} mb={2} style={{ textAlign: 'center' }}>
          <TextField
            variant="outlined"
            placeholder={'Direccion de correo electrónico'}
            value={email}
            name="email"
            type="text"
            size="small"
            fullWidth
            InputProps={{
              startAdornment: <StyledIcon />,
              style: { background: 'white', fontSize: '18px', height: '48px' },
            }}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: '18px' },
            }}
            onChange={(event) => {
              registerUserDataEvent.dispatch({
                email: event.target.value as string,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <StyledTypographyText>
            {`Enviaremos un mensaje al correo ${email} para restablecer
              la contraseña`}
          </StyledTypographyText>
        </Grid>
        <Grid item xs={12}>
          <Box textAlign="center" my={2}>
            <StyledButton
              isLoading={Boolean(loading)}
              variant="outlined"
              size="small"
              color="secondary"
              fullWidth
              disabled={!email}
              style={{ padding: '11px 0' }}
              onClick={() => {
                userForgotPassword({
                  variables: {
                    data: {
                      email,
                      form,
                      redirectUrl: window.location.origin,
                    },
                  },
                });
                onSuccess();
              }}
            >
              Aceptar
            </StyledButton>
          </Box>
          <StyledBox
            color="#FE0000"
            textAlign="center"
            fontSize={18}
            onClick={() => onBack()}
          >
            {'< Volver'}
          </StyledBox>
        </Grid>
      </Grid>
    </Box>
  );
};
