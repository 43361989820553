import React from 'react';
import { Grid, Typography, Box, Hidden } from '@mui/material';
import { Invoice } from '../../../shared/types/generated';
import moment from 'moment';

type Data = {
  data: Invoice;
};
interface IDictionary {
  [index: string]: { name: string };
}

const tanslateOptions: IDictionary = {
  COMPLETED: { name: 'Completado' },
  PROCESING: { name: 'En procese' },
  FAILED: { name: 'Fallido' },
};
export const TransactionItem: React.FC<Data> = ({ data }) => {
  const status = data?.status;
  return (
    <Grid container spacing={'10px'} my={'10px'}>
      <Grid item xs={4} lg={3}>
        <Box display={'flex'} alignItems={'center'} height={'100%'}>
          <Typography>
            {moment(data?.subscription?.startDate).format('DD-MM-YYYY')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6} lg={7}>
        <Grid container height={'100%'} spacing={1}>
          <Grid item xs={12} lg={6}>
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Typography fontWeight={'700'}>
                {data?.subscription?.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Hidden lgDown={true} lgUp={false}>
              <Box
                display={'flex'}
                height={'100%'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Typography>
                  {tanslateOptions[status as string].name}
                </Typography>
              </Box>
            </Hidden>
            <Hidden lgDown={false} lgUp={true}>
              <Box display={'flex'} justifyContent={'center'} height={'100%'}>
                <Typography>{status}</Typography>
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Box
          height={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography display={'flex'}>${data?.total}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
