/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

/** An example input */
export type CancelSubscriptionInput = {
  /** A test field */
  subscriptionId?: InputMaybe<Scalars['String']>;
};

/** A type */
export type CancelSubscriptionResponse = {
  __typename?: 'CancelSubscriptionResponse';
  /** A test field */
  message?: Maybe<Scalars['String']>;
  /** A test field */
  success?: Maybe<Scalars['Boolean']>;
};

/** A type */
export type Card = {
  __typename?: 'Card';
  /** A test field */
  brand?: Maybe<Scalars['String']>;
  /** A test field */
  cardHolderName?: Maybe<Scalars['String']>;
  /** A test field */
  creditCardNumber?: Maybe<Scalars['String']>;
  /** A test field */
  customerCardId?: Maybe<Scalars['String']>;
  /** A test field */
  expiredMonth?: Maybe<Scalars['String']>;
  /** A test field */
  expiredYear?: Maybe<Scalars['String']>;
  /** A test field */
  id?: Maybe<Scalars['String']>;
  /** The user name */
  primary?: Maybe<Scalars['Boolean']>;
  /** The user name */
  user?: Maybe<User>;
  /** A test field */
  userId?: Maybe<Scalars['Int']>;
};

/** An example input */
export type ChangePasswordInput = {
  /** User email field */
  email?: InputMaybe<Scalars['String']>;
  /** User password field */
  password?: InputMaybe<Scalars['String']>;
  /** User password field */
  passwordResetToken?: InputMaybe<Scalars['String']>;
};

/** An example input */
export type CheckUserWithRecaptchaInput = {
  /** A test field */
  email?: InputMaybe<Scalars['String']>;
  /** A test field */
  token?: InputMaybe<Scalars['String']>;
};

/** A type */
export type CheckUserWithRecaptchaResponse = {
  __typename?: 'CheckUserWithRecaptchaResponse';
  /** Check if user have password */
  passwordResetToken?: Maybe<Scalars['String']>;
  /** Success */
  success?: Maybe<Scalars['Boolean']>;
  /** Check if user have password */
  withPassword?: Maybe<Scalars['Boolean']>;
};

/** An example input */
export type CreateCardInput = {
  /** A test field */
  deviceDataId?: InputMaybe<Scalars['String']>;
  /** A test field */
  token?: InputMaybe<Scalars['String']>;
};

/** An example input */
export type CreateOpenPayChargeInput = {
  cardHolderName?: InputMaybe<Scalars['String']>;
  /** A test field */
  deviceDataId?: InputMaybe<Scalars['String']>;
  /** A test field */
  email?: InputMaybe<Scalars['String']>;
  frequency?: InputMaybe<Scalars['String']>;
  /** A test field */
  token?: InputMaybe<Scalars['String']>;
};

/** A type */
export type CreateOpenPayChargeResponse = {
  __typename?: 'CreateOpenPayChargeResponse';
  /** A test field */
  message?: Maybe<Scalars['String']>;
  /** The user name */
  redirectUrl?: Maybe<Scalars['String']>;
  /** A test field */
  success?: Maybe<Scalars['Boolean']>;
};

/** An example input */
export type CreateOpenPaySubscriptionInput = {
  cardHolderName?: InputMaybe<Scalars['String']>;
  /** A test field */
  deviceDataId?: InputMaybe<Scalars['String']>;
  /** A test field */
  email?: InputMaybe<Scalars['String']>;
  frequency?: InputMaybe<Scalars['String']>;
  /** A test field */
  token?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A type */
export type CreateOpenPaySubscriptionResponse = {
  __typename?: 'CreateOpenPaySubscriptionResponse';
  /** A test field */
  message?: Maybe<Scalars['String']>;
  /** The user name */
  subscription?: Maybe<Subscription>;
  /** A test field */
  success?: Maybe<Scalars['Boolean']>;
};

/** An example input */
export type CreatePaypalOrderInput = {
  email?: InputMaybe<Scalars['String']>;
  frequency?: InputMaybe<Scalars['String']>;
  /** User email field */
  orderId?: InputMaybe<Scalars['String']>;
  /** A test field */
  subscriptionId?: InputMaybe<Scalars['String']>;
};

/** A type */
export type CreatePaypalOrderResponse = {
  __typename?: 'CreatePaypalOrderResponse';
  /** A test field */
  message?: Maybe<Scalars['String']>;
  /** The user name */
  subscription?: Maybe<Subscription>;
  /** A test field */
  success?: Maybe<Scalars['Boolean']>;
};

/** An example input */
export type CreateUserInput = {
  /** A test field */
  avatar?: InputMaybe<Scalars['String']>;
  /** A test field */
  email?: InputMaybe<Scalars['String']>;
  /** A test field */
  name?: InputMaybe<Scalars['String']>;
  /** A test field */
  password?: InputMaybe<Scalars['String']>;
  /** A test field */
  provider?: InputMaybe<CreateUserSocialProviderInput>;
};

/** An example input */
export type CreateUserSocialProviderInput = {
  /** A test field */
  provider?: InputMaybe<Scalars['String']>;
  /** A test field */
  providerId?: InputMaybe<Scalars['String']>;
  /** A test field */
  urlRegister?: InputMaybe<Scalars['String']>;
  /** A test field */
  userId?: InputMaybe<Scalars['Int']>;
};

/** An example input */
export type DeleteCardInput = {
  /** A test field */
  id?: InputMaybe<Scalars['String']>;
};

/** A type */
export type DeleteCardResponse = {
  __typename?: 'DeleteCardResponse';
  /** A test field */
  message?: Maybe<Scalars['String']>;
  /** A test field */
  success?: Maybe<Scalars['Boolean']>;
};

/** An example input */
export type DeleteUserSocialProviderInput = {
  /** A test field */
  provider?: InputMaybe<Scalars['String']>;
  /** A test field */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A type */
export type DeleteUserSocialProviderResponse = {
  __typename?: 'DeleteUserSocialProviderResponse';
  /** A test field */
  success?: Maybe<Scalars['Boolean']>;
};

/** A type */
export type Invoice = {
  __typename?: 'Invoice';
  /** A test field */
  createdAt?: Maybe<Scalars['String']>;
  /** A test field */
  id?: Maybe<Scalars['String']>;
  /** A test field */
  payment_method?: Maybe<Scalars['String']>;
  /** Education enum */
  status?: Maybe<InvoiceStatusEnum>;
  /** The user name */
  subscription?: Maybe<Subscription>;
  /** A test field */
  subscription_id?: Maybe<Scalars['Int']>;
  /** A test field */
  total?: Maybe<Scalars['Int']>;
  /** A test field */
  transactionId?: Maybe<Scalars['String']>;
  /** The user name */
  user?: Maybe<User>;
  /** A test field */
  user_id?: Maybe<Scalars['Int']>;
};

/** An example enum */
export enum InvoiceStatusEnum {
  /** test */
  Completed = 'COMPLETED',
  /** test */
  Failed = 'FAILED',
  /** test */
  Processing = 'PROCESSING'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** A mutation */
  CheckUserWithRecaptchaMutation?: Maybe<CheckUserWithRecaptchaResponse>;
  /** A mutation */
  SignUpMutation?: Maybe<SignUpResponse>;
  /** A mutation */
  SignUpWithSocialProviderMutation?: Maybe<SignUpResponse>;
  /** A mutation */
  UserForgotPasswordMutation?: Maybe<UserForgotPasswordResponse>;
  /** A mutation */
  cancelOpenPaySubscription?: Maybe<CancelSubscriptionResponse>;
  /** A mutation */
  cancelPaypalSubscription?: Maybe<CancelSubscriptionResponse>;
  /** A mutation */
  cancelSubscription?: Maybe<CancelSubscriptionResponse>;
  /** A mutation */
  changeUserPasswordMutation?: Maybe<User>;
  /** A mutation */
  createCard?: Maybe<Card>;
  /** A mutation */
  createOpenPayCharge?: Maybe<CreateOpenPayChargeResponse>;
  /** A mutation */
  createOpenPaySubscription?: Maybe<CreateOpenPaySubscriptionResponse>;
  /** A mutation */
  createPaypalOrder?: Maybe<CreatePaypalOrderResponse>;
  createUser?: Maybe<User>;
  createUserSocialProvider?: Maybe<UserSocialProviderType>;
  /** A mutation */
  deleteCard?: Maybe<DeleteCardResponse>;
  /** A mutation */
  deleteUserSocialProvider?: Maybe<DeleteUserSocialProviderResponse>;
  /** A mutation */
  updateCard?: Maybe<Card>;
  /** A mutation */
  updatePaymentDefault?: Maybe<UpdatePaymentDefaultResponse>;
  /** A mutation */
  updateUser?: Maybe<User>;
};


export type MutationCheckUserWithRecaptchaMutationArgs = {
  data?: InputMaybe<CheckUserWithRecaptchaInput>;
};


export type MutationSignUpMutationArgs = {
  data?: InputMaybe<SignUpInput>;
};


export type MutationSignUpWithSocialProviderMutationArgs = {
  data?: InputMaybe<SignUpWithSocialProviderInput>;
};


export type MutationUserForgotPasswordMutationArgs = {
  data?: InputMaybe<UserForgotPasswordInput>;
};


export type MutationCancelOpenPaySubscriptionArgs = {
  data?: InputMaybe<CancelSubscriptionInput>;
};


export type MutationCancelPaypalSubscriptionArgs = {
  data?: InputMaybe<CancelSubscriptionInput>;
};


export type MutationCancelSubscriptionArgs = {
  data?: InputMaybe<CancelSubscriptionInput>;
};


export type MutationChangeUserPasswordMutationArgs = {
  data?: InputMaybe<ChangePasswordInput>;
};


export type MutationCreateCardArgs = {
  data?: InputMaybe<CreateCardInput>;
};


export type MutationCreateOpenPayChargeArgs = {
  data?: InputMaybe<CreateOpenPayChargeInput>;
};


export type MutationCreateOpenPaySubscriptionArgs = {
  data?: InputMaybe<CreateOpenPaySubscriptionInput>;
};


export type MutationCreatePaypalOrderArgs = {
  data?: InputMaybe<CreatePaypalOrderInput>;
};


export type MutationCreateUserArgs = {
  data?: InputMaybe<CreateUserInput>;
};


export type MutationCreateUserSocialProviderArgs = {
  data?: InputMaybe<CreateUserSocialProviderInput>;
};


export type MutationDeleteCardArgs = {
  data?: InputMaybe<DeleteCardInput>;
};


export type MutationDeleteUserSocialProviderArgs = {
  data?: InputMaybe<DeleteUserSocialProviderInput>;
};


export type MutationUpdateCardArgs = {
  data?: InputMaybe<UpdateCardInput>;
};


export type MutationUpdatePaymentDefaultArgs = {
  data?: InputMaybe<UpdatePaymentDefaultInput>;
};


export type MutationUpdateUserArgs = {
  data?: InputMaybe<UpdateUserInput>;
};

/** A type */
export type Notification = {
  __typename?: 'Notification';
  /** A test field */
  createdAt?: Maybe<Scalars['String']>;
  /** A test field */
  id?: Maybe<Scalars['String']>;
  /** Message */
  message?: Maybe<Scalars['String']>;
  /** A test field */
  updatedAt?: Maybe<Scalars['String']>;
};

export type NotificationPagination = {
  __typename?: 'NotificationPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<Notification>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
  /** The last page (number of pages) */
  last_page: Scalars['Int'];
  /** Number of items returned per page */
  per_page: Scalars['Int'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']>;
  /** Number of total items selected by the query */
  total: Scalars['Int'];
};

/** An example enum */
export enum OrderEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** A type */
export type Post = {
  __typename?: 'Post';
  /** A list of posts written by the user */
  author?: Maybe<UserPost>;
  /** Post content */
  content?: Maybe<Scalars['String']>;
  /** Post created */
  created_at?: Maybe<Scalars['String']>;
  /** User id */
  id?: Maybe<Scalars['Int']>;
  /** Post title */
  image?: Maybe<Scalars['String']>;
  /** Post title */
  title?: Maybe<Scalars['String']>;
};

export type PostPagination = {
  __typename?: 'PostPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<Post>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
  /** The last page (number of pages) */
  last_page: Scalars['Int'];
  /** Number of items returned per page */
  per_page: Scalars['Int'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']>;
  /** Number of total items selected by the query */
  total: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  /** A query */
  cards?: Maybe<Array<Maybe<Card>>>;
  /** A query */
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  /** A query */
  notifications?: Maybe<NotificationPagination>;
  post?: Maybe<Post>;
  posts?: Maybe<PostPagination>;
  user?: Maybe<User>;
  /** A query */
  userByEmail?: Maybe<User>;
};


export type QueryCardsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<OrderEnum>;
  page?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type QueryInvoicesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<OrderEnum>;
  page?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type QueryNotificationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<OrderEnum>;
  page?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type QueryPostArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPostsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<OrderEnum>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryUserByEmailArgs = {
  email?: InputMaybe<Scalars['String']>;
  isSocialProvider?: InputMaybe<Scalars['Boolean']>;
  provider?: InputMaybe<Scalars['String']>;
  providerId?: InputMaybe<Scalars['String']>;
};

/** An example input */
export type SignUpInput = {
  /** A user email field */
  email?: InputMaybe<Scalars['String']>;
  /** A user password field */
  password?: InputMaybe<Scalars['String']>;
};

/** A type */
export type SignUpResponse = {
  __typename?: 'SignUpResponse';
  /** Token */
  access_token?: Maybe<Scalars['String']>;
};

/** An example input */
export type SignUpWithSocialProviderInput = {
  /** A user email field */
  email?: InputMaybe<Scalars['String']>;
  /** A user email field */
  provider?: InputMaybe<Scalars['String']>;
  /** A user email field */
  provider_id?: InputMaybe<Scalars['String']>;
};

/** A type */
export type Subscription = {
  __typename?: 'Subscription';
  /** A test field */
  description?: Maybe<Scalars['String']>;
  /** A test field */
  endDate?: Maybe<Scalars['String']>;
  /** A test field */
  frequency?: Maybe<Scalars['String']>;
  /** A test field */
  id?: Maybe<Scalars['Int']>;
  /** A test field */
  name?: Maybe<Scalars['String']>;
  /** A test field */
  paymentMethod?: Maybe<Scalars['String']>;
  /** A test field */
  startDate?: Maybe<Scalars['String']>;
  /** A test field */
  status?: Maybe<Scalars['String']>;
  /** A test field */
  subscriptionId?: Maybe<Scalars['String']>;
  /** A test field */
  userId?: Maybe<Scalars['Int']>;
};

/** An example input */
export type UpdateCardInput = {
  /** A test field */
  cardHolderName?: InputMaybe<Scalars['String']>;
  /** A test field */
  creditCardNumber?: InputMaybe<Scalars['String']>;
  /** A test field */
  cvc?: InputMaybe<Scalars['String']>;
  /** A test field */
  expiredDate?: InputMaybe<Scalars['String']>;
  /** A test field */
  id?: InputMaybe<Scalars['Int']>;
};

/** An example input */
export type UpdatePaymentDefaultInput = {
  /** A test field */
  cardHolderName?: InputMaybe<Scalars['String']>;
  /** A test field */
  creditCardNumber?: InputMaybe<Scalars['String']>;
  /** A test field */
  cvv?: InputMaybe<Scalars['String']>;
  /** A test field */
  deviceDataId?: InputMaybe<Scalars['String']>;
  /** A test field */
  expirationMonth?: InputMaybe<Scalars['String']>;
  /** A test field */
  expirationYear?: InputMaybe<Scalars['String']>;
};

/** A type */
export type UpdatePaymentDefaultResponse = {
  __typename?: 'UpdatePaymentDefaultResponse';
  /** A test field */
  message?: Maybe<Scalars['String']>;
  /** A test field */
  success?: Maybe<Scalars['Boolean']>;
};

/** An example input */
export type UpdateUserInput = {
  /** The user name */
  avatar?: InputMaybe<Scalars['String']>;
  /** The user name */
  birthday?: InputMaybe<Scalars['String']>;
  /** Education enum */
  education?: InputMaybe<UserEducationEnum>;
  /** The user email */
  email?: InputMaybe<Scalars['String']>;
  /** Education enum */
  file?: InputMaybe<Scalars['Upload']>;
  /** User id */
  id: Scalars['Int'];
  /** The user name */
  name?: InputMaybe<Scalars['String']>;
  /** The user password */
  password?: InputMaybe<Scalars['String']>;
  /** Education enum */
  sex?: InputMaybe<UserSexEnum>;
};

/** A type */
export type User = {
  __typename?: 'User';
  /** The user name */
  avatar?: Maybe<Scalars['String']>;
  /** The user name */
  birthday?: Maybe<Scalars['String']>;
  /** The user name */
  card?: Maybe<Card>;
  /** Education enum */
  education?: Maybe<UserEducationEnum>;
  /** The user email */
  email: Scalars['String'];
  /** User id */
  id: Scalars['Int'];
  /** The user name */
  name: Scalars['String'];
  /** The user password */
  password: Scalars['String'];
  /** The user name */
  provider?: Maybe<Array<Maybe<UserSocialProviderType>>>;
  /** Education enum */
  sex?: Maybe<UserSexEnum>;
  /** The user name */
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
};


/** A type */
export type UserSubscriptionsArgs = {
  order_by?: InputMaybe<OrderEnum>;
};

/** An example enum */
export enum UserEducationEnum {
  /** test */
  Graduate = 'GRADUATE',
  /** test */
  Primary = 'PRIMARY',
  /** test */
  Secondary = 'SECONDARY',
  /** test */
  Superior = 'SUPERIOR'
}

/** An example input */
export type UserForgotPasswordInput = {
  /** A user email field */
  email?: InputMaybe<Scalars['String']>;
  /** A redirect article */
  form?: InputMaybe<Scalars['String']>;
  /** A redirect article */
  redirectUrl?: InputMaybe<Scalars['String']>;
};

/** A type */
export type UserForgotPasswordResponse = {
  __typename?: 'UserForgotPasswordResponse';
  /** Response */
  success?: Maybe<Scalars['Boolean']>;
};

/** A type */
export type UserPost = {
  __typename?: 'UserPost';
  /** Post id */
  id?: Maybe<Scalars['Int']>;
  /** Post id */
  post_id?: Maybe<Scalars['Int']>;
  /** User model */
  user?: Maybe<User>;
  /** User id */
  user_id?: Maybe<Scalars['Int']>;
};

/** An example enum */
export enum UserSexEnum {
  /** test */
  Female = 'FEMALE',
  /** test */
  Male = 'MALE',
  /** test */
  NoApply = 'NO_APPLY'
}

/** An example enum */
export enum UserSocialProviderEnum {
  /** APPLE */
  Apple = 'APPLE',
  /** FACEBOOK */
  Facebook = 'FACEBOOK',
  /** GOOGLE */
  Google = 'GOOGLE',
  /** SYSTEM */
  System = 'SYSTEM'
}

/** A type */
export type UserSocialProviderType = {
  __typename?: 'UserSocialProviderType';
  /** Post id */
  id?: Maybe<Scalars['Int']>;
  provider?: Maybe<UserSocialProviderEnum>;
  /** A test field */
  providerId?: Maybe<Scalars['String']>;
  /** A test field */
  urlRegister?: Maybe<Scalars['String']>;
  /** User id */
  user_id?: Maybe<Scalars['Int']>;
};

export type CardsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
}>;


export type CardsQuery = { __typename?: 'Query', cards: Array<{ __typename?: 'Card', id: string | null, brand: string | null, creditCardNumber: string | null, customerCardId: string | null, expiredYear: string | null, expiredMonth: string | null, cardHolderName: string | null, primary: boolean | null, user: { __typename?: 'User', id: number, name: string } | null } | null> | null };

export type CreateCardMutationVariables = Exact<{
  data: CreateCardInput;
}>;


export type CreateCardMutation = { __typename?: 'Mutation', createCard: { __typename?: 'Card', id: string | null, brand: string | null, creditCardNumber: string | null, customerCardId: string | null, expiredYear: string | null, expiredMonth: string | null, cardHolderName: string | null, primary: boolean | null, user: { __typename?: 'User', id: number, name: string } | null } | null };

export type DeleteCardMutationVariables = Exact<{
  data: DeleteCardInput;
}>;


export type DeleteCardMutation = { __typename?: 'Mutation', deleteCard: { __typename?: 'DeleteCardResponse', success: boolean | null, message: string | null } | null };

export type CreateOpenPaySubscriptionMutationVariables = Exact<{
  data: CreateOpenPaySubscriptionInput;
}>;


export type CreateOpenPaySubscriptionMutation = { __typename?: 'Mutation', createOpenPaySubscription: { __typename?: 'CreateOpenPaySubscriptionResponse', success: boolean | null, message: string | null, subscription: { __typename?: 'Subscription', id: number | null, name: string | null, description: string | null, startDate: string | null, endDate: string | null, paymentMethod: string | null, status: string | null, frequency: string | null } | null } | null };

export type CreateOpenPayChargeMutationVariables = Exact<{
  data?: InputMaybe<CreateOpenPayChargeInput>;
}>;


export type CreateOpenPayChargeMutation = { __typename?: 'Mutation', createOpenPayCharge: { __typename?: 'CreateOpenPayChargeResponse', redirectUrl: string | null, message: string | null, success: boolean | null } | null };

export type InvoicesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
}>;


export type InvoicesQuery = { __typename?: 'Query', invoices: Array<{ __typename?: 'Invoice', total: number | null, status: InvoiceStatusEnum | null, user_id: number | null, createdAt: string | null, user: { __typename?: 'User', id: number, name: string, email: string } | null, subscription: { __typename?: 'Subscription', id: number | null, name: string | null, startDate: string | null, endDate: string | null } | null } | null> | null };

export type CreatePaypalOrderMutationVariables = Exact<{
  data: CreatePaypalOrderInput;
}>;


export type CreatePaypalOrderMutation = { __typename?: 'Mutation', createPaypalOrder: { __typename?: 'CreatePaypalOrderResponse', success: boolean | null, message: string | null, subscription: { __typename?: 'Subscription', id: number | null, paymentMethod: string | null } | null } | null };

export type CreateUserMutationVariables = Exact<{
  data?: InputMaybe<CreateUserInput>;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: number, email: string, name: string, avatar: string | null } | null };

export type CreateUserProviderMutationVariables = Exact<{
  data?: InputMaybe<CreateUserSocialProviderInput>;
}>;


export type CreateUserProviderMutation = { __typename?: 'Mutation', createUserSocialProvider: { __typename?: 'UserSocialProviderType', id: number | null, user_id: number | null, provider: UserSocialProviderEnum | null } | null };

export type UserByEmailQueryVariables = Exact<{
  email: Scalars['String'];
  provider?: InputMaybe<Scalars['String']>;
  isSocialProvider?: InputMaybe<Scalars['Boolean']>;
  providerId?: InputMaybe<Scalars['String']>;
}>;


export type UserByEmailQuery = { __typename?: 'Query', userByEmail: { __typename?: 'User', id: number, email: string, name: string, avatar: string | null, password: string, provider: Array<{ __typename?: 'UserSocialProviderType', id: number | null, provider: UserSocialProviderEnum | null, providerId: string | null } | null> | null } | null };

export type SigUpMutationMutationVariables = Exact<{
  data?: InputMaybe<SignUpInput>;
}>;


export type SigUpMutationMutation = { __typename?: 'Mutation', SignUpMutation: { __typename?: 'SignUpResponse', access_token: string | null } | null };

export type ChangeUserPasswordMutationVariables = Exact<{
  data?: InputMaybe<ChangePasswordInput>;
}>;


export type ChangeUserPasswordMutation = { __typename?: 'Mutation', changeUserPasswordMutation: { __typename?: 'User', id: number, email: string } | null };

export type SignUpWithSocialProviderMutationVariables = Exact<{
  data?: InputMaybe<SignUpWithSocialProviderInput>;
}>;


export type SignUpWithSocialProviderMutation = { __typename?: 'Mutation', SignUpWithSocialProviderMutation: { __typename?: 'SignUpResponse', access_token: string | null } | null };

export type CheckUserWithRecaptchaMutationMutationVariables = Exact<{
  data?: InputMaybe<CheckUserWithRecaptchaInput>;
}>;


export type CheckUserWithRecaptchaMutationMutation = { __typename?: 'Mutation', CheckUserWithRecaptchaMutation: { __typename?: 'CheckUserWithRecaptchaResponse', success: boolean | null, withPassword: boolean | null, passwordResetToken: string | null } | null };

export type FetchUserQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchUserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: number, name: string, email: string, avatar: string | null, sex: UserSexEnum | null, education: UserEducationEnum | null, birthday: string | null, subscriptions: Array<{ __typename?: 'Subscription', id: number | null, name: string | null, description: string | null, startDate: string | null, endDate: string | null, paymentMethod: string | null, status: string | null, frequency: string | null, subscriptionId: string | null } | null> | null, provider: Array<{ __typename?: 'UserSocialProviderType', id: number | null, provider: UserSocialProviderEnum | null, providerId: string | null } | null> | null, card: { __typename?: 'Card', id: string | null, expiredYear: string | null, expiredMonth: string | null, cardHolderName: string | null, creditCardNumber: string | null } | null } | null };

export type UserForgotPasswordMutationMutationVariables = Exact<{
  data?: InputMaybe<UserForgotPasswordInput>;
}>;


export type UserForgotPasswordMutationMutation = { __typename?: 'Mutation', UserForgotPasswordMutation: { __typename?: 'UserForgotPasswordResponse', success: boolean | null } | null };

export type UpdateUserMutationVariables = Exact<{
  data?: InputMaybe<UpdateUserInput>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: number, name: string, email: string, avatar: string | null, sex: UserSexEnum | null, education: UserEducationEnum | null, birthday: string | null, subscriptions: Array<{ __typename?: 'Subscription', id: number | null, name: string | null, description: string | null, startDate: string | null, endDate: string | null, paymentMethod: string | null, status: string | null, frequency: string | null } | null> | null } | null };

export type DeleteUserSocialProviderMutationVariables = Exact<{
  data?: InputMaybe<DeleteUserSocialProviderInput>;
}>;


export type DeleteUserSocialProviderMutation = { __typename?: 'Mutation', deleteUserSocialProvider: { __typename?: 'DeleteUserSocialProviderResponse', success: boolean | null } | null };

export type UpdatePaymentDefaultMutationVariables = Exact<{
  data?: InputMaybe<UpdatePaymentDefaultInput>;
}>;


export type UpdatePaymentDefaultMutation = { __typename?: 'Mutation', updatePaymentDefault: { __typename?: 'UpdatePaymentDefaultResponse', message: string | null, success: boolean | null } | null };

export type CancelSubscriptionMutationVariables = Exact<{
  data?: InputMaybe<CancelSubscriptionInput>;
}>;


export type CancelSubscriptionMutation = { __typename?: 'Mutation', cancelSubscription: { __typename?: 'CancelSubscriptionResponse', message: string | null, success: boolean | null } | null };

export type CancelOpenPaySubscriptionMutationVariables = Exact<{
  data?: InputMaybe<CancelSubscriptionInput>;
}>;


export type CancelOpenPaySubscriptionMutation = { __typename?: 'Mutation', cancelOpenPaySubscription: { __typename?: 'CancelSubscriptionResponse', message: string | null, success: boolean | null } | null };

export type CancelPaypalSubscriptionMutationVariables = Exact<{
  data?: InputMaybe<CancelSubscriptionInput>;
}>;


export type CancelPaypalSubscriptionMutation = { __typename?: 'Mutation', cancelPaypalSubscription: { __typename?: 'CancelSubscriptionResponse', message: string | null, success: boolean | null } | null };

export type FetchNotificationsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['Int']>;
}>;


export type FetchNotificationsQuery = { __typename?: 'Query', notifications: { __typename?: 'NotificationPagination', data: Array<{ __typename?: 'Notification', id: string | null, message: string | null, createdAt: string | null } | null> | null } | null };


export const CardsDocument = gql`
    query Cards($limit: Int, $page: Int, $userId: Int) {
  cards(limit: $limit, page: $page, userId: $userId) {
    id
    brand
    creditCardNumber
    customerCardId
    expiredYear
    expiredMonth
    cardHolderName
    primary
    user {
      id
      name
    }
  }
}
    `;

/**
 * __useCardsQuery__
 *
 * To run a query within a React component, call `useCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCardsQuery(baseOptions?: Apollo.QueryHookOptions<CardsQuery, CardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardsQuery, CardsQueryVariables>(CardsDocument, options);
      }
export function useCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardsQuery, CardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardsQuery, CardsQueryVariables>(CardsDocument, options);
        }
export type CardsQueryHookResult = ReturnType<typeof useCardsQuery>;
export type CardsLazyQueryHookResult = ReturnType<typeof useCardsLazyQuery>;
export type CardsQueryResult = Apollo.QueryResult<CardsQuery, CardsQueryVariables>;
export const CreateCardDocument = gql`
    mutation CreateCard($data: CreateCardInput!) {
  createCard(data: $data) {
    id
    brand
    creditCardNumber
    customerCardId
    expiredYear
    expiredMonth
    cardHolderName
    primary
    user {
      id
      name
    }
  }
}
    `;
export type CreateCardMutationFn = Apollo.MutationFunction<CreateCardMutation, CreateCardMutationVariables>;

/**
 * __useCreateCardMutation__
 *
 * To run a mutation, you first call `useCreateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardMutation, { data, loading, error }] = useCreateCardMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCardMutation(baseOptions?: Apollo.MutationHookOptions<CreateCardMutation, CreateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCardMutation, CreateCardMutationVariables>(CreateCardDocument, options);
      }
export type CreateCardMutationHookResult = ReturnType<typeof useCreateCardMutation>;
export type CreateCardMutationResult = Apollo.MutationResult<CreateCardMutation>;
export type CreateCardMutationOptions = Apollo.BaseMutationOptions<CreateCardMutation, CreateCardMutationVariables>;
export const DeleteCardDocument = gql`
    mutation DeleteCard($data: DeleteCardInput!) {
  deleteCard(data: $data) {
    success
    message
  }
}
    `;
export type DeleteCardMutationFn = Apollo.MutationFunction<DeleteCardMutation, DeleteCardMutationVariables>;

/**
 * __useDeleteCardMutation__
 *
 * To run a mutation, you first call `useDeleteCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardMutation, { data, loading, error }] = useDeleteCardMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteCardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCardMutation, DeleteCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCardMutation, DeleteCardMutationVariables>(DeleteCardDocument, options);
      }
export type DeleteCardMutationHookResult = ReturnType<typeof useDeleteCardMutation>;
export type DeleteCardMutationResult = Apollo.MutationResult<DeleteCardMutation>;
export type DeleteCardMutationOptions = Apollo.BaseMutationOptions<DeleteCardMutation, DeleteCardMutationVariables>;
export const CreateOpenPaySubscriptionDocument = gql`
    mutation CreateOpenPaySubscription($data: CreateOpenPaySubscriptionInput!) {
  createOpenPaySubscription(data: $data) {
    success
    message
    subscription {
      id
      name
      description
      startDate
      endDate
      paymentMethod
      status
      frequency
    }
  }
}
    `;
export type CreateOpenPaySubscriptionMutationFn = Apollo.MutationFunction<CreateOpenPaySubscriptionMutation, CreateOpenPaySubscriptionMutationVariables>;

/**
 * __useCreateOpenPaySubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateOpenPaySubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpenPaySubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpenPaySubscriptionMutation, { data, loading, error }] = useCreateOpenPaySubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOpenPaySubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOpenPaySubscriptionMutation, CreateOpenPaySubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOpenPaySubscriptionMutation, CreateOpenPaySubscriptionMutationVariables>(CreateOpenPaySubscriptionDocument, options);
      }
export type CreateOpenPaySubscriptionMutationHookResult = ReturnType<typeof useCreateOpenPaySubscriptionMutation>;
export type CreateOpenPaySubscriptionMutationResult = Apollo.MutationResult<CreateOpenPaySubscriptionMutation>;
export type CreateOpenPaySubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateOpenPaySubscriptionMutation, CreateOpenPaySubscriptionMutationVariables>;
export const CreateOpenPayChargeDocument = gql`
    mutation CreateOpenPayCharge($data: CreateOpenPayChargeInput) {
  createOpenPayCharge(data: $data) {
    redirectUrl
    message
    success
  }
}
    `;
export type CreateOpenPayChargeMutationFn = Apollo.MutationFunction<CreateOpenPayChargeMutation, CreateOpenPayChargeMutationVariables>;

/**
 * __useCreateOpenPayChargeMutation__
 *
 * To run a mutation, you first call `useCreateOpenPayChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpenPayChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpenPayChargeMutation, { data, loading, error }] = useCreateOpenPayChargeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOpenPayChargeMutation(baseOptions?: Apollo.MutationHookOptions<CreateOpenPayChargeMutation, CreateOpenPayChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOpenPayChargeMutation, CreateOpenPayChargeMutationVariables>(CreateOpenPayChargeDocument, options);
      }
export type CreateOpenPayChargeMutationHookResult = ReturnType<typeof useCreateOpenPayChargeMutation>;
export type CreateOpenPayChargeMutationResult = Apollo.MutationResult<CreateOpenPayChargeMutation>;
export type CreateOpenPayChargeMutationOptions = Apollo.BaseMutationOptions<CreateOpenPayChargeMutation, CreateOpenPayChargeMutationVariables>;
export const InvoicesDocument = gql`
    query Invoices($limit: Int, $page: Int, $userId: Int) {
  invoices(limit: $limit, page: $page, userId: $userId) {
    total
    status
    user_id
    createdAt
    user {
      id
      name
      email
    }
    subscription {
      id
      name
      startDate
      endDate
    }
  }
}
    `;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
      }
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
        }
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const CreatePaypalOrderDocument = gql`
    mutation CreatePaypalOrder($data: CreatePaypalOrderInput!) {
  createPaypalOrder(data: $data) {
    success
    message
    subscription {
      id
      paymentMethod
    }
  }
}
    `;
export type CreatePaypalOrderMutationFn = Apollo.MutationFunction<CreatePaypalOrderMutation, CreatePaypalOrderMutationVariables>;

/**
 * __useCreatePaypalOrderMutation__
 *
 * To run a mutation, you first call `useCreatePaypalOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaypalOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaypalOrderMutation, { data, loading, error }] = useCreatePaypalOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePaypalOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaypalOrderMutation, CreatePaypalOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaypalOrderMutation, CreatePaypalOrderMutationVariables>(CreatePaypalOrderDocument, options);
      }
export type CreatePaypalOrderMutationHookResult = ReturnType<typeof useCreatePaypalOrderMutation>;
export type CreatePaypalOrderMutationResult = Apollo.MutationResult<CreatePaypalOrderMutation>;
export type CreatePaypalOrderMutationOptions = Apollo.BaseMutationOptions<CreatePaypalOrderMutation, CreatePaypalOrderMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($data: CreateUserInput) {
  createUser(data: $data) {
    id
    email
    name
    avatar
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CreateUserProviderDocument = gql`
    mutation CreateUserProvider($data: CreateUserSocialProviderInput) {
  createUserSocialProvider(data: $data) {
    id
    user_id
    provider
  }
}
    `;
export type CreateUserProviderMutationFn = Apollo.MutationFunction<CreateUserProviderMutation, CreateUserProviderMutationVariables>;

/**
 * __useCreateUserProviderMutation__
 *
 * To run a mutation, you first call `useCreateUserProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserProviderMutation, { data, loading, error }] = useCreateUserProviderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserProviderMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserProviderMutation, CreateUserProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserProviderMutation, CreateUserProviderMutationVariables>(CreateUserProviderDocument, options);
      }
export type CreateUserProviderMutationHookResult = ReturnType<typeof useCreateUserProviderMutation>;
export type CreateUserProviderMutationResult = Apollo.MutationResult<CreateUserProviderMutation>;
export type CreateUserProviderMutationOptions = Apollo.BaseMutationOptions<CreateUserProviderMutation, CreateUserProviderMutationVariables>;
export const UserByEmailDocument = gql`
    query userByEmail($email: String!, $provider: String, $isSocialProvider: Boolean, $providerId: String) {
  userByEmail(
    email: $email
    provider: $provider
    isSocialProvider: $isSocialProvider
    providerId: $providerId
  ) {
    id
    email
    name
    avatar
    password
    provider {
      id
      provider
      providerId
    }
  }
}
    `;

/**
 * __useUserByEmailQuery__
 *
 * To run a query within a React component, call `useUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *      provider: // value for 'provider'
 *      isSocialProvider: // value for 'isSocialProvider'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<UserByEmailQuery, UserByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, options);
      }
export function useUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByEmailQuery, UserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, options);
        }
export type UserByEmailQueryHookResult = ReturnType<typeof useUserByEmailQuery>;
export type UserByEmailLazyQueryHookResult = ReturnType<typeof useUserByEmailLazyQuery>;
export type UserByEmailQueryResult = Apollo.QueryResult<UserByEmailQuery, UserByEmailQueryVariables>;
export const SigUpMutationDocument = gql`
    mutation SigUpMutation($data: SignUpInput) {
  SignUpMutation(data: $data) {
    access_token
  }
}
    `;
export type SigUpMutationMutationFn = Apollo.MutationFunction<SigUpMutationMutation, SigUpMutationMutationVariables>;

/**
 * __useSigUpMutationMutation__
 *
 * To run a mutation, you first call `useSigUpMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigUpMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sigUpMutationMutation, { data, loading, error }] = useSigUpMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSigUpMutationMutation(baseOptions?: Apollo.MutationHookOptions<SigUpMutationMutation, SigUpMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SigUpMutationMutation, SigUpMutationMutationVariables>(SigUpMutationDocument, options);
      }
export type SigUpMutationMutationHookResult = ReturnType<typeof useSigUpMutationMutation>;
export type SigUpMutationMutationResult = Apollo.MutationResult<SigUpMutationMutation>;
export type SigUpMutationMutationOptions = Apollo.BaseMutationOptions<SigUpMutationMutation, SigUpMutationMutationVariables>;
export const ChangeUserPasswordDocument = gql`
    mutation ChangeUserPassword($data: ChangePasswordInput) {
  changeUserPasswordMutation(data: $data) {
    id
    email
  }
}
    `;
export type ChangeUserPasswordMutationFn = Apollo.MutationFunction<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>;

/**
 * __useChangeUserPasswordMutation__
 *
 * To run a mutation, you first call `useChangeUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserPasswordMutation, { data, loading, error }] = useChangeUserPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangeUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>(ChangeUserPasswordDocument, options);
      }
export type ChangeUserPasswordMutationHookResult = ReturnType<typeof useChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationResult = Apollo.MutationResult<ChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationOptions = Apollo.BaseMutationOptions<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>;
export const SignUpWithSocialProviderDocument = gql`
    mutation SignUpWithSocialProvider($data: SignUpWithSocialProviderInput) {
  SignUpWithSocialProviderMutation(data: $data) {
    access_token
  }
}
    `;
export type SignUpWithSocialProviderMutationFn = Apollo.MutationFunction<SignUpWithSocialProviderMutation, SignUpWithSocialProviderMutationVariables>;

/**
 * __useSignUpWithSocialProviderMutation__
 *
 * To run a mutation, you first call `useSignUpWithSocialProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpWithSocialProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpWithSocialProviderMutation, { data, loading, error }] = useSignUpWithSocialProviderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignUpWithSocialProviderMutation(baseOptions?: Apollo.MutationHookOptions<SignUpWithSocialProviderMutation, SignUpWithSocialProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpWithSocialProviderMutation, SignUpWithSocialProviderMutationVariables>(SignUpWithSocialProviderDocument, options);
      }
export type SignUpWithSocialProviderMutationHookResult = ReturnType<typeof useSignUpWithSocialProviderMutation>;
export type SignUpWithSocialProviderMutationResult = Apollo.MutationResult<SignUpWithSocialProviderMutation>;
export type SignUpWithSocialProviderMutationOptions = Apollo.BaseMutationOptions<SignUpWithSocialProviderMutation, SignUpWithSocialProviderMutationVariables>;
export const CheckUserWithRecaptchaMutationDocument = gql`
    mutation CheckUserWithRecaptchaMutation($data: CheckUserWithRecaptchaInput) {
  CheckUserWithRecaptchaMutation(data: $data) {
    success
    withPassword
    passwordResetToken
  }
}
    `;
export type CheckUserWithRecaptchaMutationMutationFn = Apollo.MutationFunction<CheckUserWithRecaptchaMutationMutation, CheckUserWithRecaptchaMutationMutationVariables>;

/**
 * __useCheckUserWithRecaptchaMutationMutation__
 *
 * To run a mutation, you first call `useCheckUserWithRecaptchaMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckUserWithRecaptchaMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkUserWithRecaptchaMutationMutation, { data, loading, error }] = useCheckUserWithRecaptchaMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckUserWithRecaptchaMutationMutation(baseOptions?: Apollo.MutationHookOptions<CheckUserWithRecaptchaMutationMutation, CheckUserWithRecaptchaMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckUserWithRecaptchaMutationMutation, CheckUserWithRecaptchaMutationMutationVariables>(CheckUserWithRecaptchaMutationDocument, options);
      }
export type CheckUserWithRecaptchaMutationMutationHookResult = ReturnType<typeof useCheckUserWithRecaptchaMutationMutation>;
export type CheckUserWithRecaptchaMutationMutationResult = Apollo.MutationResult<CheckUserWithRecaptchaMutationMutation>;
export type CheckUserWithRecaptchaMutationMutationOptions = Apollo.BaseMutationOptions<CheckUserWithRecaptchaMutationMutation, CheckUserWithRecaptchaMutationMutationVariables>;
export const FetchUserDocument = gql`
    query fetchUser {
  user {
    id
    name
    email
    avatar
    sex
    education
    birthday
    subscriptions {
      id
      name
      description
      startDate
      endDate
      paymentMethod
      status
      frequency
      subscriptionId
    }
    provider {
      id
      provider
      providerId
    }
    card {
      id
      expiredYear
      expiredMonth
      cardHolderName
      creditCardNumber
    }
  }
}
    `;

/**
 * __useFetchUserQuery__
 *
 * To run a query within a React component, call `useFetchUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchUserQuery(baseOptions?: Apollo.QueryHookOptions<FetchUserQuery, FetchUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchUserQuery, FetchUserQueryVariables>(FetchUserDocument, options);
      }
export function useFetchUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchUserQuery, FetchUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchUserQuery, FetchUserQueryVariables>(FetchUserDocument, options);
        }
export type FetchUserQueryHookResult = ReturnType<typeof useFetchUserQuery>;
export type FetchUserLazyQueryHookResult = ReturnType<typeof useFetchUserLazyQuery>;
export type FetchUserQueryResult = Apollo.QueryResult<FetchUserQuery, FetchUserQueryVariables>;
export const UserForgotPasswordMutationDocument = gql`
    mutation userForgotPasswordMutation($data: UserForgotPasswordInput) {
  UserForgotPasswordMutation(data: $data) {
    success
  }
}
    `;
export type UserForgotPasswordMutationMutationFn = Apollo.MutationFunction<UserForgotPasswordMutationMutation, UserForgotPasswordMutationMutationVariables>;

/**
 * __useUserForgotPasswordMutationMutation__
 *
 * To run a mutation, you first call `useUserForgotPasswordMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserForgotPasswordMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userForgotPasswordMutationMutation, { data, loading, error }] = useUserForgotPasswordMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserForgotPasswordMutationMutation(baseOptions?: Apollo.MutationHookOptions<UserForgotPasswordMutationMutation, UserForgotPasswordMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserForgotPasswordMutationMutation, UserForgotPasswordMutationMutationVariables>(UserForgotPasswordMutationDocument, options);
      }
export type UserForgotPasswordMutationMutationHookResult = ReturnType<typeof useUserForgotPasswordMutationMutation>;
export type UserForgotPasswordMutationMutationResult = Apollo.MutationResult<UserForgotPasswordMutationMutation>;
export type UserForgotPasswordMutationMutationOptions = Apollo.BaseMutationOptions<UserForgotPasswordMutationMutation, UserForgotPasswordMutationMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($data: UpdateUserInput) {
  updateUser(data: $data) {
    id
    name
    email
    avatar
    sex
    education
    birthday
    subscriptions {
      id
      name
      description
      startDate
      endDate
      paymentMethod
      status
      frequency
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserSocialProviderDocument = gql`
    mutation deleteUserSocialProvider($data: DeleteUserSocialProviderInput) {
  deleteUserSocialProvider(data: $data) {
    success
  }
}
    `;
export type DeleteUserSocialProviderMutationFn = Apollo.MutationFunction<DeleteUserSocialProviderMutation, DeleteUserSocialProviderMutationVariables>;

/**
 * __useDeleteUserSocialProviderMutation__
 *
 * To run a mutation, you first call `useDeleteUserSocialProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSocialProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSocialProviderMutation, { data, loading, error }] = useDeleteUserSocialProviderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteUserSocialProviderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserSocialProviderMutation, DeleteUserSocialProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserSocialProviderMutation, DeleteUserSocialProviderMutationVariables>(DeleteUserSocialProviderDocument, options);
      }
export type DeleteUserSocialProviderMutationHookResult = ReturnType<typeof useDeleteUserSocialProviderMutation>;
export type DeleteUserSocialProviderMutationResult = Apollo.MutationResult<DeleteUserSocialProviderMutation>;
export type DeleteUserSocialProviderMutationOptions = Apollo.BaseMutationOptions<DeleteUserSocialProviderMutation, DeleteUserSocialProviderMutationVariables>;
export const UpdatePaymentDefaultDocument = gql`
    mutation UpdatePaymentDefault($data: UpdatePaymentDefaultInput) {
  updatePaymentDefault(data: $data) {
    message
    success
  }
}
    `;
export type UpdatePaymentDefaultMutationFn = Apollo.MutationFunction<UpdatePaymentDefaultMutation, UpdatePaymentDefaultMutationVariables>;

/**
 * __useUpdatePaymentDefaultMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentDefaultMutation, { data, loading, error }] = useUpdatePaymentDefaultMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePaymentDefaultMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentDefaultMutation, UpdatePaymentDefaultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentDefaultMutation, UpdatePaymentDefaultMutationVariables>(UpdatePaymentDefaultDocument, options);
      }
export type UpdatePaymentDefaultMutationHookResult = ReturnType<typeof useUpdatePaymentDefaultMutation>;
export type UpdatePaymentDefaultMutationResult = Apollo.MutationResult<UpdatePaymentDefaultMutation>;
export type UpdatePaymentDefaultMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentDefaultMutation, UpdatePaymentDefaultMutationVariables>;
export const CancelSubscriptionDocument = gql`
    mutation CancelSubscription($data: CancelSubscriptionInput) {
  cancelSubscription(data: $data) {
    message
    success
  }
}
    `;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, options);
      }
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const CancelOpenPaySubscriptionDocument = gql`
    mutation CancelOpenPaySubscription($data: CancelSubscriptionInput) {
  cancelOpenPaySubscription(data: $data) {
    message
    success
  }
}
    `;
export type CancelOpenPaySubscriptionMutationFn = Apollo.MutationFunction<CancelOpenPaySubscriptionMutation, CancelOpenPaySubscriptionMutationVariables>;

/**
 * __useCancelOpenPaySubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelOpenPaySubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOpenPaySubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOpenPaySubscriptionMutation, { data, loading, error }] = useCancelOpenPaySubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCancelOpenPaySubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelOpenPaySubscriptionMutation, CancelOpenPaySubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelOpenPaySubscriptionMutation, CancelOpenPaySubscriptionMutationVariables>(CancelOpenPaySubscriptionDocument, options);
      }
export type CancelOpenPaySubscriptionMutationHookResult = ReturnType<typeof useCancelOpenPaySubscriptionMutation>;
export type CancelOpenPaySubscriptionMutationResult = Apollo.MutationResult<CancelOpenPaySubscriptionMutation>;
export type CancelOpenPaySubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelOpenPaySubscriptionMutation, CancelOpenPaySubscriptionMutationVariables>;
export const CancelPaypalSubscriptionDocument = gql`
    mutation CancelPaypalSubscription($data: CancelSubscriptionInput) {
  cancelPaypalSubscription(data: $data) {
    message
    success
  }
}
    `;
export type CancelPaypalSubscriptionMutationFn = Apollo.MutationFunction<CancelPaypalSubscriptionMutation, CancelPaypalSubscriptionMutationVariables>;

/**
 * __useCancelPaypalSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelPaypalSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPaypalSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPaypalSubscriptionMutation, { data, loading, error }] = useCancelPaypalSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCancelPaypalSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelPaypalSubscriptionMutation, CancelPaypalSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelPaypalSubscriptionMutation, CancelPaypalSubscriptionMutationVariables>(CancelPaypalSubscriptionDocument, options);
      }
export type CancelPaypalSubscriptionMutationHookResult = ReturnType<typeof useCancelPaypalSubscriptionMutation>;
export type CancelPaypalSubscriptionMutationResult = Apollo.MutationResult<CancelPaypalSubscriptionMutation>;
export type CancelPaypalSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelPaypalSubscriptionMutation, CancelPaypalSubscriptionMutationVariables>;
export const FetchNotificationsDocument = gql`
    query FetchNotifications($userId: Int) {
  notifications(limit: 10, page: 1, userId: $userId, order_by: DESC) {
    data {
      id
      message
      createdAt
    }
  }
}
    `;

/**
 * __useFetchNotificationsQuery__
 *
 * To run a query within a React component, call `useFetchNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchNotificationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFetchNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<FetchNotificationsQuery, FetchNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchNotificationsQuery, FetchNotificationsQueryVariables>(FetchNotificationsDocument, options);
      }
export function useFetchNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchNotificationsQuery, FetchNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchNotificationsQuery, FetchNotificationsQueryVariables>(FetchNotificationsDocument, options);
        }
export type FetchNotificationsQueryHookResult = ReturnType<typeof useFetchNotificationsQuery>;
export type FetchNotificationsLazyQueryHookResult = ReturnType<typeof useFetchNotificationsLazyQuery>;
export type FetchNotificationsQueryResult = Apollo.QueryResult<FetchNotificationsQuery, FetchNotificationsQueryVariables>;