import React, { useState, useEffect } from 'react';
import { styled } from '@mui/styles';
import { Button } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import { fetchUserGoogle } from '../auth-actions';
import {
  useCreateUserMutation,
  useUserByEmailLazyQuery,
} from '../../../shared/types/generated';
import { GoogleUserType } from '../auth-types';
import { getCurrentUrl } from '../auth-utils';
import { changeIsLoginStore } from '../auth-events';

const StyledButton = styled(Button)({
  position: 'relative',
  justifyContent: 'center!important',
  borderRadius: '5px!important',
  height: 45,
  paddingRight: '30px!important',
  border: '1px solid #FFCCCC!important',
  backgroundColor: 'white !important',
});

const StyledImage = styled('img')({
  marginRight: 10,
});

type GoogleButtonProps = {
  onSuccess?: (email: string) => void;
};

export const GoogleButton: React.FC<GoogleButtonProps> = ({ onSuccess }) => {
  const [user, setUser] = useState<GoogleUserType | undefined | null>(
    undefined,
  );
  const { isLogin } = useStore(changeIsLoginStore);
  const [createUserMutation] = useCreateUserMutation({
    onCompleted: ({ createUser }) => {
      if (createUser?.id) {
        if (onSuccess && createUser?.email) onSuccess(createUser?.email);
      }
    },
    onError: () => {},
  });

  const [fetchUser] = useUserByEmailLazyQuery({
    onCompleted: ({ userByEmail }) => {
      if (userByEmail?.id) {
        if (onSuccess && user?.email) onSuccess(user?.email);
      } else {
        createUserMutation({
          variables: {
            data: {
              email: String(user?.email),
              name: user?.name,
              avatar: user?.picture,
              password: `$%-/${user?.email}+2023,`,
              provider: {
                provider: 'GOOGLE',
                urlRegister: getCurrentUrl(),
                providerId: `${user?.email}`,
              },
            },
          },
        });
      }
    },
    onError: () => {},
  });

  const [googleUserCallback] = useCallAction(fetchUserGoogle, {
    onCompleted: (data) => setUser(data),
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      googleUserCallback(tokenResponse);
    },
    onError: (error) => console.log('Login Failed:', error),
  });

  useEffect(() => {
    if (user) {
      fetchUser({
        variables: {
          email: user?.email as string,
          isSocialProvider: true,
          provider: 'GOOGLE',
          providerId: `${user?.email}`,
        },
      });
    }
  }, [user, fetchUser]);

  return (
    <>
      <StyledButton
        type="button"
        className=""
        variant="outlined"
        fullWidth
        onClick={() => googleLogin()}
      >
        <StyledImage src="/google-logo.png" width={20} height={20} alt="logo" />
        {`${isLogin ? 'ENTRAR' : 'REGISTRARME'}`} con Google
      </StyledButton>
    </>
  );
};
