import { OpenPayCreditCardType } from './openpay-types';
import { PaymentTime, PaymentType } from './openpay-constants';
import { client } from '../../shared/apollo';
import {
  CreateOpenPayChargeDocument,
  CreateOpenPayChargeMutation,
  CreateOpenPayChargeMutationVariables,
  CreateOpenPaySubscriptionDocument,
  CreateOpenPaySubscriptionMutation,
  CreateOpenPaySubscriptionMutationVariables,
  CreateOpenPaySubscriptionResponse,
  CreateOpenPayChargeResponse,
} from '../../shared/types/generated';
import {
  OPEN_PAY_ENV,
  OPEN_PAY_MERCHANT_ID,
  OPEN_PAY_PK_ID,
} from '../../shared/constans';

type CreateChargeSubscriptionType = {
  email: string;
  subscription: {
    paymentMethod: string;
    frequency: string;
  };
  card: OpenPayCreditCardType;
};
export const translate = (e: string): string => {
  console.log({ e });
  let msg = 'Hubo un error al procesar el pago';
  switch (e) {
    /* ERRORES GENERALES */
    case '1000':
    case '1004':
    case '1005':
      msg = 'Servicio no disponible.';
      break;
    /* ERRORES TARJETA */
    case '3001':
    case '3004':
    case '3005':
    case '3007':
      msg = 'La tarjeta fue rechazada.';
      break;
    case '3002':
      msg = 'La tarjeta ha expirado.';
      break;
    case '3003':
      msg = 'La tarjeta no tiene fondos suficientes.';
      break;
    case '3006':
      msg =
        'La operación no esta permitida para este cliente o esta transacción.';
      break;
    case '3008':
      msg = 'La tarjeta no es soportada en transacciones en línea.';
      break;
    case '3009':
      msg = 'La tarjeta fue reportada como perdida.';
      break;
    case '3010':
      msg = 'El banco ha restringido la tarjeta.';
      break;
    case '3011':
      msg =
        'El banco ha solicitado que la tarjeta sea retenida. Contacte al banco.';
      break;
    case '3012':
      msg =
        'Se requiere solicitar al banco autorización para realizar este pago.';
      break;
    case '2007':
      msg =
        'El número de la tarjeta utilizada es valida solo en modo de prueba.';
      break;
    default: /* Demás errores 400 */
      msg = 'La petición no pudo ser procesada.';
      break;
  }
  return msg;
};

export const createOpenPayInstance = (): void => {
  /**
   * PRUEBAS
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-undef
  OpenPay.setId(OPEN_PAY_MERCHANT_ID);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-undef
  OpenPay.setApiKey(OPEN_PAY_PK_ID);

  /**
   * PRODUCCIÓN
   */
  // eslint-disable-next-line no-undef
  // OpenPay.setId('m3cv4ahm37lxgfsxk2sp');
  // // eslint-disable-next-line no-undef
  // OpenPay.setApiKey('pk_9035fff184164bf9a4b0eb0ab6c614af');

  /**
   * ACTIVAR MODO DE PRUEBAS
   * TRUE: ACTIVADO EL MODO DE PRUEBAS
   * FALSE: DESACTIVAR MODO DE PRUEBAS
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-undef
  OpenPay.setSandboxMode(OPEN_PAY_ENV === 'development');
};

export const createChargeSubscription = async (
  data: CreateChargeSubscriptionType,
  onSuccess: (data: CreateOpenPayChargeResponse) => void,
): Promise<void> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-undef
  OpenPay.token.create(
    data.card,
    async (response: {
      data: {
        card: OpenPayCreditCardType;
        id: string;
      };
      status: number;
    }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-undef

      const deviceDataId = OpenPay.deviceData.setup();
      console.log({ response });
      const createChargeSubscriptionData = await client.mutate<
        CreateOpenPayChargeMutation,
        CreateOpenPayChargeMutationVariables
      >({
        mutation: CreateOpenPayChargeDocument,
        variables: {
          data: {
            deviceDataId: deviceDataId,
            token: response.data.id,
            email: data.email,
            cardHolderName: data.card.holder_name,
            frequency: data.subscription.frequency,
          },
        },
      });
      return onSuccess(
        createChargeSubscriptionData.data
          ?.createOpenPayCharge as CreateOpenPayChargeResponse,
      );
    },
    function (response: any) {
      const desc =
        response.data.description != undefined
          ? translate(response.data.error_code.toString())
          : response.message;
      console.log('error', { response });
      return onSuccess({
        success: false,
        message: desc,
      } as CreateOpenPayChargeResponse);
    },
  );
};

export const createSubscriptionOpenPay = async (
  card: OpenPayCreditCardType,
  email: string,
  payment: PaymentType,
  time: PaymentTime,
  onSuccess: (data: CreateOpenPaySubscriptionResponse) => void,
): Promise<void> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-undef
  OpenPay.token.create(
    card,
    async (response: {
      data: {
        card: OpenPayCreditCardType;
        id: string;
      };
      status: number;
    }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-undef
      const deviceDataId = OpenPay.deviceData.setup();
      const createSubscription = await client.mutate<
        CreateOpenPaySubscriptionMutation,
        CreateOpenPaySubscriptionMutationVariables
      >({
        mutation: CreateOpenPaySubscriptionDocument,
        variables: {
          data: {
            email: email as string,
            cardHolderName: card.holder_name,
            deviceDataId: deviceDataId,
            token: response.data.id,
            frequency: time,
          },
        },
      });

      return onSuccess(
        createSubscription.data
          ?.createOpenPaySubscription as CreateOpenPaySubscriptionResponse,
      );
    },
    function (response: any) {
      const desc =
        response.data.description != undefined
          ? translate(response.data.error_code.toString())
          : response.message;
      console.log('error', { response });
      return onSuccess({
        success: false,
        message: desc,
      } as CreateOpenPaySubscriptionResponse);
    },
  );
};
