import React, { useState } from 'react';
import { Grid, Typography, Box, Hidden } from '@mui/material';
import { styled } from '@mui/styles';
import {
  Subscription,
  useCancelOpenPaySubscriptionMutation,
  useCancelPaypalSubscriptionMutation,
  useCancelSubscriptionMutation,
} from '../../../shared/types/generated';
import moment from 'moment/moment';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { openDialogAlert } from '../../alert/alert-actions';
import { useAuth } from '../../auth/auth-hook';
import { DefaultDialog } from '../../../shared/components/dialog/DefaultDialog';
import { getSubscriptionStatus } from '../../subscription/subscription-utils';

const StyledCancelButton = styled(ButtonDefault)({
  position: 'relative',
  justifyContent: 'center!important',
  alignItems: 'center',
  borderRadius: '5px!important',
  height: 45,
  border: '1px solid #FE0000!important',
  width: 180,
  display: 'flex',
  color: '#FE0000!important',
});

export const LibraryItem: React.FC<{ item: Subscription | null }> = ({
  item,
}) => {
  const { refetch } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [cancelOpenPaySubscription, { loading: cancelOpenPayLoading }] =
    useCancelOpenPaySubscriptionMutation({
      onCompleted: (data) => {
        if (data.cancelOpenPaySubscription?.success) {
          setIsOpen(false);
          return openDialogAlert(
            data.cancelOpenPaySubscription?.message as string,
            () => refetch(),
          );
        } else {
          return openDialogAlert(
            data.cancelOpenPaySubscription?.message as string,
          );
        }
      },
    });

  const [cancelSubscription, { loading }] = useCancelSubscriptionMutation({
    onCompleted: (data) => {
      if (data.cancelSubscription?.success) {
        setIsOpen(false);
        return openDialogAlert(data.cancelSubscription?.message as string, () =>
          refetch(),
        );
      } else {
        return openDialogAlert(data.cancelSubscription?.message as string);
      }
    },
  });

  const [cancelPaypalSubscription, { loading: cancelPaypalLoading }] =
    useCancelPaypalSubscriptionMutation({
      onCompleted: (data) => {
        if (data.cancelPaypalSubscription?.success) {
          setIsOpen(false);
          return openDialogAlert(
            data.cancelPaypalSubscription?.message as string,
            () => refetch(),
          );
        } else {
          return openDialogAlert(
            data.cancelPaypalSubscription?.message as string,
          );
        }
      },
    });
  const status = getSubscriptionStatus(item);
  let cancel: any;

  if (item?.paymentMethod === 'Open Pay') {
    cancel = () =>
      cancelOpenPaySubscription({
        variables: {
          data: {
            subscriptionId: item?.subscriptionId,
          },
        },
      });
  } else if (item?.paymentMethod === 'PayPal') {
    cancel = () =>
      cancelPaypalSubscription({
        variables: {
          data: {
            subscriptionId: item?.subscriptionId,
          },
        },
      });
  } else {
    cancel = () =>
      cancelSubscription({
        variables: {
          data: {
            subscriptionId: item?.id?.toString(),
          },
        },
      });
  }

  let colorCustom = <></>;
  switch (item?.status) {
    case 'ACTIVE':
      colorCustom = (
        <Typography
          color={'green'}
          style={{ fontSize: '16px', padding: '10px' }}
        >
          {status}
        </Typography>
      );
      break;
    case 'INACTIVE':
      colorCustom = (
        <Typography
          color={'#e9bd15'}
          style={{ fontSize: '16px', padding: '10px' }}
        >
          {status}
        </Typography>
      );
      break;
    case 'CANCELLED':
      colorCustom = (
        <Typography color={'red'} style={{ fontSize: '16px', padding: '10px' }}>
          {status}
        </Typography>
      );
      break;
  }
  const isPaypal = item?.paymentMethod === 'PayPal';
  const isOpenPay = item?.paymentMethod == 'Open Pay';

  return (
    <Grid
      container
      my={'10px'}
      paddingTop={'10px'}
      borderBottom={'1px solid #CCD0D4'}
      paddingBottom={'15px'}
    >
      <Grid item xs={3} md={3}>
        <Box display={'flex'} alignItems={'center'} height={'100%'}>
          <Typography>
            {moment(item?.startDate).format('DD-MM-YYYY')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={3} md={3}>
        <Box display={'flex'} alignItems={'center'} height={'100%'}>
          <Typography>{moment(item?.endDate).format('DD-MM-YYYY')}</Typography>
        </Box>
      </Grid>
      <Grid item xs={3} md={3}>
        <Box
          justifyContent={'center'}
          alignItems={'center'}
          display="flex"
          height={'100%'}
        >
          <Typography fontWeight={'700'}>{item?.name}</Typography>
        </Box>
      </Grid>
      <Grid item xs={3} md={3}>
        <Hidden lgDown={true} lgUp={false}>
          <Box
            display={'flex'}
            height={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {colorCustom}
          </Box>
        </Hidden>
        <Hidden lgDown={false} lgUp={true}>
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
          >
            {colorCustom}
          </Box>
        </Hidden>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box display="flex" alignItems="center" justifyContent="center">
          {item?.status === 'ACTIVE' ? (
            <StyledCancelButton
              variant="outlined"
              size="large"
              onClick={() => setIsOpen(true)}
              style={{
                fontSize: isPaypal || isOpenPay ? 12 : 10,
              }}
            >
              {`${isPaypal || isOpenPay ? 'Cancelar renovación' : 'Cancelar'}`}
            </StyledCancelButton>
          ) : null}
        </Box>
        <DefaultDialog
          isOpen={isOpen}
          onCancel={() => setIsOpen(false)}
          isLoading={loading || cancelPaypalLoading || cancelOpenPayLoading}
          onSuccess={() => cancel()}
        >
          <div style={{ fontSize: 18, padding: '20px 0' }}>
            ¿Estas seguro que deseas cancelar tu suscripción?
          </div>
        </DefaultDialog>
      </Grid>
    </Grid>
  );
};
