import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { Notification } from '../../../shared/types/generated';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');
type Data = {
  notification?: Notification | null;
};

moment.locale('es');

export const HistoryItem: React.FC<Data> = ({ notification }) => {
  return (
    <Grid container spacing={'10px'} my={'10px'}>
      <Grid
        item
        xs={4}
        lg={2}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box height={'30px'} width={'30px'}>
          <img
            src="/favicon-noroeste.png"
            alt="logo"
            width={30}
            height={30}
            style={{ flexGrow: 1 }}
          />
        </Box>
      </Grid>
      <Grid item xs={8} lg={10}>
        <Box alignItems={'center'} height={'100%'}>
          <Typography color={'#A2A2A2'}>
            {moment(notification?.createdAt).format('DD-MM-YYYY')}
          </Typography>
          <Typography>{notification?.message}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
