import React from 'react';
import {
  Divider,
  Typography,
  List,
  ListItem,
  ListItemText,
  Theme,
  Box,
  Hidden,
  Drawer,
  Button,
} from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useStore } from '@cobuildlab/react-simple-state';
import { openMobileMenuEvent } from '../dashboard-events';
import { closeMobileMenu } from '../dashboard-actions';
import { useAuth } from '../../auth/auth-hook';
import PowerSettingsNewSharpIcon from '@mui/icons-material/PowerSettingsNewSharp';

type ActualPosition = {
  num?: number;
};
const useStyles = makeStyles<Theme>(() =>
  createStyles({
    textRed: {
      color: '#FA0000',
    },
    helpCenter: {
      color: 'black',
      '&:hover': {
        color: '#FA0000',
      },
    },
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
      color: '#000!important',

      margin: '0 0',
    },
    button: {
      color: 'white!important',
    },
  }),
);

export const Menu: React.FC<ActualPosition> = ({ num = 0 }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { open } = useStore(openMobileMenuEvent);
  const { subscription } = useAuth();

  return (
    <>
      <Hidden mdDown={false} mdUp={true}>
        <Drawer anchor="left" open={open} onClose={() => closeMobileMenu()}>
          <Box
            sx={{ width: 250, height: '100%' }}
            onClick={() => closeMobileMenu()}
          >
            <List component="nav" aria-label="mailbox folders">
              <ListItem style={{ justifyContent: 'center' }}>
                <a
                  className={classes.cleanButton}
                  href="https://noroeste.com.mx/"
                >
                  <img
                    src="/LOGO_NOROESTE_MOBILE.svg"
                    alt="logo"
                    style={{ flexShrink: 1 }}
                  />
                </a>
              </ListItem>
              <Divider />
              <ListItem
                className={num === 1 ? classes.textRed : ''}
                divider
                onClick={() => {
                  navigate('/');
                }}
              >
                <ListItemText primary="Perfil" />
                <ArrowForwardIosIcon fontSize="small" />
              </ListItem>
              <ListItem
                className={num === 2 ? classes.textRed : ''}
                divider
                onClick={() => {
                  navigate('/transacciones');
                }}
              >
                <ListItemText primary="Transacciones" />
                <ArrowForwardIosIcon fontSize="small" />
              </ListItem>
              <ListItem
                className={num === 3 ? classes.textRed : ''}
                divider
                onClick={() => {
                  navigate('/biblioteca');
                }}
              >
                <ListItemText primary="Biblioteca" />
                <ArrowForwardIosIcon fontSize="small" />
              </ListItem>
              <ListItem
                className={num === 4 ? classes.textRed : ''}
                divider
                onClick={() => {
                  navigate('/detalles-de-facturacion');
                }}
              >
                <ListItemText primary="Detalles de facturación" />
                <ArrowForwardIosIcon fontSize="small" />
              </ListItem>
              <ListItem
                className={num === 5 ? classes.textRed : ''}
                divider
                onClick={() => {
                  navigate('/historial');
                }}
              >
                <ListItemText primary="Historial" />
                <ArrowForwardIosIcon fontSize="small" />
              </ListItem>
            </List>

            <List component="nav" aria-label="mailbox folders">
              <ListItem>
                <Typography fontWeight="bold">SOPORTE</Typography>
              </ListItem>
              <Divider />
              <a
                className={classes.helpCenter}
                style={{ textDecoration: 'none' }}
                target="_blank"
                href="https://ac.noroeste.com.mx/autoayuda"
              >
                <ListItem className={num === 7 ? classes.textRed : ''} divider>
                  <ListItemText
                    style={{ fontSize: '16px' }}
                    primary="Centro de ayuda"
                  />
                  <ArrowForwardIosIcon fontSize="small" />
                </ListItem>
              </a>
            </List>
            <List component="nav" aria-label="mailbox folders">
              <ListItem>
                {!subscription ? (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    disableElevation
                    style={{ textTransform: 'capitalize', height: 45 }}
                    onClick={() => navigate('/suscripciones')}
                    fullWidth
                  >
                    <Typography>
                      <Hidden lgDown={true} lgUp={false}>
                        Quiero{' '}
                      </Hidden>
                      acceso total
                    </Typography>
                  </Button>
                ) : null}
              </ListItem>
            </List>
          </Box>
          <Box>
            <List component="nav" aria-label="mailbox folders">
              <Divider sx={{ marginBottom: '10px' }} />
              <a
                className={classes.helpCenter}
                style={{ textDecoration: 'none' }}
                href="/logout"
              >
                <ListItem className={num === 7 ? classes.textRed : ''}>
                  <ListItemText
                    style={{ fontSize: '16px' }}
                    primary="Cerrar sesión"
                  />
                  <PowerSettingsNewSharpIcon fontSize="small" />
                </ListItem>
              </a>
            </List>
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown={true} mdUp={false}>
        <Box
          sx={{
            height: 'calc(90vh - 80px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <List component="nav" aria-label="mailbox folders">
              <ListItem>
                <Typography color="primary" fontWeight="bold">
                  TU CONTENIDO
                </Typography>
              </ListItem>
              <Divider />
              <ListItem
                className={num === 1 ? classes.textRed : ''}
                divider
                onClick={() => {
                  navigate('/');
                }}
              >
                <ListItemText primary="Perfil" />
                <ArrowForwardIosIcon fontSize="small" />
              </ListItem>
              <ListItem
                className={num === 2 ? classes.textRed : ''}
                divider
                onClick={() => {
                  navigate('/transacciones');
                }}
              >
                <ListItemText primary="Transacciones" />
                <ArrowForwardIosIcon fontSize="small" />
              </ListItem>
              <ListItem
                className={num === 3 ? classes.textRed : ''}
                divider
                onClick={() => {
                  navigate('/biblioteca');
                }}
              >
                <ListItemText primary="Biblioteca" />
                <ArrowForwardIosIcon fontSize="small" />
              </ListItem>
              <ListItem
                className={num === 4 ? classes.textRed : ''}
                divider
                onClick={() => {
                  navigate('/detalles-de-facturacion');
                }}
              >
                <ListItemText primary="Detalles de facturación" />
                <ArrowForwardIosIcon fontSize="small" />
              </ListItem>
              <ListItem
                className={num === 5 ? classes.textRed : ''}
                divider
                onClick={() => {
                  navigate('/historial');
                }}
              >
                <ListItemText primary="Historial" />
                <ArrowForwardIosIcon fontSize="small" />
              </ListItem>
            </List>

            <List component="nav" aria-label="mailbox folders">
              <ListItem>
                <Typography fontWeight="bold">SOPORTE</Typography>
              </ListItem>
              <Divider />
              <a
                className={classes.helpCenter}
                style={{ textDecoration: 'none' }}
                target="_blank"
                href="https://ac.noroeste.com.mx/autoayuda"
              >
                <ListItem className={num === 7 ? classes.textRed : ''} divider>
                  <ListItemText
                    style={{ fontSize: '16px' }}
                    primary="Centro de ayuda"
                  />
                  <ArrowForwardIosIcon fontSize="small" />
                </ListItem>
              </a>
            </List>
          </Box>
          <Box>
            <List component="nav" aria-label="mailbox folders">
              <Divider sx={{ marginBottom: '10px' }} />
              <a
                className={classes.helpCenter}
                style={{ textDecoration: 'none' }}
                href="/logout"
              >
                <ListItem className={num === 7 ? classes.textRed : ''}>
                  <ListItemText
                    style={{ fontSize: '16px' }}
                    primary="Cerrar sesión"
                  />
                  <PowerSettingsNewSharpIcon fontSize="small" />
                </ListItem>
              </a>
            </List>
          </Box>
        </Box>
      </Hidden>
    </>
  );
};
