import React, { useState } from 'react';
import { Box, Grid, TextField } from '@mui/material';
import { Key } from '@mui/icons-material';
import { EmailOutlined } from '@mui/icons-material';
import { styled } from '@mui/styles';
import validator from 'validator';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { useSigUpMutationMutation } from '../../../shared/types/generated';
import { registerUserDataEvent } from '../auth-events';
import { useEvent } from '@cobuildlab/react-simple-state';

type AuthLoginFormProps = {
  onSuccess: (accessToken: string) => void;
  onPossibleForgot?: () => void;
  onBack?: () => void;
  onTestEmail?: () => void;
};

const StyledButton = styled(ButtonDefault)({
  borderRadius: '5px!important',
  backgroundColor: '#FE0000!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#FFCCCC!important',
  },
  width: 200,
});

const StyledIcon = styled(EmailOutlined)({
  color: '#999999',
  fontSize: '20px!important',
  paddingRight: '5px',
});
const StyledIconKey = styled(Key)({
  color: '#999999',
  fontSize: '20px!important',
  paddingRight: '5px',
});

const StyledVisibilityIcon = styled(VisibilityIcon)({
  cursor: 'pointer',
  color: '#999999',
  fontSize: '20px!important',
});

export const AuthLoginForm: React.FC<AuthLoginFormProps> = ({
  onSuccess,
  onPossibleForgot,
  onTestEmail,
  onBack,
}) => {
  const { email, password } = useEvent(registerUserDataEvent);
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [passwordType, setPasswordType] = useState<string | undefined>(
    undefined,
  );

  const [signUpMutation] = useSigUpMutationMutation({
    onCompleted: ({ SignUpMutation }) => {
      if (SignUpMutation?.access_token && onSuccess) {
        onSuccess(SignUpMutation?.access_token);
      }
      setIsLoading(false);
    },
    onError: () => {
      setError(!error);
      if (onTestEmail) {
        onTestEmail();
      }
      setIsLoading(false);
    },
  });

  const handleVisibility = (): void => {
    if (!passwordType) setPasswordType('text');
    else setPasswordType(undefined);
  };

  const onSubmit = (): void => {
    console.log({
      variables: {
        data: {
          email,
          password,
        },
      },
    });
    setIsLoading(true);

    signUpMutation({
      variables: {
        data: {
          email,
          password,
        },
      },
    });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ textAlign: 'center' }}></Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            placeholder={'Direccion de correo electrónico'}
            value={email}
            name="email"
            type="text"
            size="small"
            fullWidth
            InputProps={{
              startAdornment: <StyledIcon />,
              style: { background: 'white', fontSize: '18px', height: '48px' },
            }}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: '18px' },
            }}
            onChange={(event) => {
              registerUserDataEvent.dispatch({
                email: event.target.value as string,
                password,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={error}
            placeholder={'Contraseña'}
            name="password"
            type={passwordType ?? 'password'}
            value={password}
            fullWidth
            size="small"
            variant="outlined"
            helperText={error && 'Contraseña inválida, intente de nuevo'}
            FormHelperTextProps={{ style: { fontSize: '16px' } }}
            InputProps={{
              endAdornment: (
                <StyledVisibilityIcon onClick={() => handleVisibility()} />
              ),
              startAdornment: <StyledIconKey />,
              style: { fontSize: '18px', background: 'white', height: '48px' },
            }}
            onChange={(event) => {
              registerUserDataEvent.dispatch({
                email,
                password: event.target.value as string,
              });
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Box textAlign="center" marginBottom={2}>
            <StyledButton
              isLoading={isLoading}
              variant="contained"
              size="small"
              fullWidth
              color="secondary"
              disabled={!(email && validator.isEmail(email as string))}
              onClick={() => onSubmit()}
              style={{ padding: '11px 0' }}
            >
              Siguiente
            </StyledButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
