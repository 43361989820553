import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './shared/css/theme';
import { CookiesProvider } from 'react-cookie';
import { client } from './shared/apollo';
import { ApolloProvider } from '@apollo/client';
import { ToastProvider } from 'react-toast-notifications';
import { DialogAlert } from './modules/alert/DialogAlert';
import { AuthProvider } from './modules/auth/AuthProvider';
import { bugsnagStart } from './modules/bugsnag/bugsnag';

bugsnagStart();
/**
 * App component.
 *
 * @returns {React.FC} - App component.
 */
export const App: React.FC = () => {
  return (
    <ToastProvider
      placement="bottom-left"
      autoDismiss
      autoDismissTimeout={10000}
    >
      <ApolloProvider client={client}>
        <BrowserRouter>
          <CookiesProvider>
            <AuthProvider>
              <ThemeProvider theme={theme}>
                <Routes />
                <DialogAlert />
              </ThemeProvider>
            </AuthProvider>
          </CookiesProvider>
        </BrowserRouter>
      </ApolloProvider>
    </ToastProvider>
  );
};
