import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';

type AuthForgotPasswordType = {
  onBack: () => void;
  onTimePass?: () => void;
};
const StyledBox = styled(Box)({
  cursor: 'pointer',
  marginTop: '15px',
});
export const AuthChangePasswordEmail: React.FC<AuthForgotPasswordType> = ({
  onBack,
  onTimePass,
}) => {
  const [aux, setAux] = useState<boolean>(false);
  useEffect(() => {
    let timeout: null | ReturnType<typeof setTimeout> = null;
    timeout = setTimeout(() => {
      if (onTimePass) {
        onTimePass();
        setAux(true);
      }
    }, 4000);
    if (aux) {
      clearTimeout(timeout);
    }
  }, [aux, onTimePass]);
  return (
    <Box>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Box mb={4}>
            {/**
               * <img
              src="/LOGO_NOROESTE.svg"
              alt="logo"
              width={225}
              height={25}
              style={{ paddingBottom: '20px' }}
            />{' '}
               */}
            <Typography fontSize={'19px'} fontWeight={500}>
              El correo con los pasos para cambiar la contraseña a sido enviado,
              será redireccionado al inicio.
            </Typography>
          </Box>
        </Grid>
        <StyledBox
          color="#FE0000"
          textAlign="center"
          fontSize={18}
          onClick={() => onBack()}
        >
          {'< Volver'}
        </StyledBox>
      </Grid>
    </Box>
  );
};
