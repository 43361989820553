import { createContext } from 'react';
import { Subscription, User } from '../../shared/types/generated';
import { ApolloError } from '@apollo/client/errors';

export type DefaultValuesContextType = {
  user?: User;
  isAuthenticated: boolean;
  subscription: Subscription | undefined | null;
  error?: ApolloError | Error | undefined;
  refetch: () => void;
  handleSocialProvider: (userSocialProvider: string) => void;
  isLoading?: boolean;
  socialProvider?: string;
};

const defaultValues = {
  isAuthenticated: false,
  isLoading: true,
  user: undefined,
  refetch: () => {},
  handleSocialProvider: () => {},
  subscription: undefined,
  error: undefined,
  socialProvider: undefined,
};

export const Context = createContext<DefaultValuesContextType>(defaultValues);

export const { Consumer } = Context;
export const { Provider } = Context;
