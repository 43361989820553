import { useEffect, useState } from 'react';
import { FacebookUserType } from './auth-types';
import { createAction, useSubscription } from '@cobuildlab/react-simple-state';
import { authFacebookErrorEvent, authFacebookEvent } from './auth-events';
import axios from 'axios';
import { openDialogAlert } from '../alert/alert-actions';
import { useNavigate } from 'react-router-dom';

type UseAuthFacebook = {
  user?: FacebookUserType | null;
  isAuthenticated?: boolean;
  error?: boolean;
};

export const useAuthFacebook = (): UseAuthFacebook => {
  const [error, setError] = useState<boolean>(false);
  const [user, setUser] = useState<FacebookUserType | undefined | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const url: URL = new URL(window.location.href.replace('#', ''));
    const params: URLSearchParams = url.searchParams;

    const authFacebook = createAction(
      authFacebookEvent,
      authFacebookErrorEvent,
      async () => {
        const response = await axios.get('https://graph.facebook.com/me', {
          params: {
            fields: 'name,gender,location,picture,email',
            access_token: params.get('access_token'),
            debug: 'all',
          },
        });

        return response.data;
      },
    );

    if (!isAuthenticated) {
      authFacebook();
      setUser(user);
    }
  }, [isAuthenticated, user]);

  useSubscription(authFacebookEvent, (_user) => {
    if (!_user?.email) {
      openDialogAlert(
        'No pudimos crear una cuenta usando Facebook porque no hay una dirección de correo electrónico asociada con su cuenta de Facebook.',
      );
      return navigate('/');
    }
    setIsAuthenticated(true);
    setUser(_user);
  });

  useSubscription(authFacebookErrorEvent, () => {
    setError(true);
  });

  return { user, isAuthenticated, error };
};
