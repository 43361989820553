import React, { useEffect } from 'react';
import { MainLoader } from '../../shared/components/MainLoader';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { DOMAIN_SITE } from '../../shared/constans';

export const AuthLogout: React.FC = () => {
  const [cookies, , removeCookie] = useCookies(['_u', 'loginWithSocial']);
  const navigate = useNavigate();

  useEffect(() => {
    if (cookies._u || cookies.loginWithSocial) {
      removeCookie('_u', { path: '/', domain: DOMAIN_SITE });
      removeCookie('loginWithSocial', { path: '/', domain: DOMAIN_SITE });
      console.log('test');
    } else {
      navigate('/autenticacion');
    }
  }, [cookies._u, cookies.loginWithSocial, navigate, removeCookie]);

  return <MainLoader />;
};
