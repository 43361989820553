import React from 'react';
import { Theme, Box, Select, FormControl, MenuItem } from '@mui/material';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    divider: {
      borderWidth: 2,
      borderColor: 'black!important',
      marginTop: '15px!important',
      marginBottom: '15px!important',
    },
    button: {
      borderColor: theme.palette.primary.main,
    },
    textRed: {
      color: '#FA0000',
    },
    cleanButton: {
      background: '#ffffff!important',
      border: 'none!important',
      cursor: 'pointer',
      borderRadius: 'none!important',
      width: '100%!important',
      height: '30px',
      outline: 'none!important',
    },
    inputStyle: {
      width: '100%!important',
    },
    buttonUnderlinedStyle: {
      background: '#ffffff!important',
      border: 'none!important',
      borderRadius: '0!important',
      cursor: 'pointer',
      padding: '0 0!important',

      width: '100%!important',
    },
    buttonSave: {
      background: '#FA0000!important',
      color: 'white!important',
    },
    buttonConnect: {
      background: '#FA0000!important',
      color: 'white!important',
      width: '180px',
    },
    buttonUnconnect: {
      background: '!whiteimportant',
      color: '#FA0000!important',
      border: '1px solid #FA0000',
      width: '180px',
    },
    textSocial: {
      margin: '0px 0px 0px 15px!important',
    },
  }),
);

type DropDownProps = {
  items?: { label?: string; value?: string }[];
  selected?: string;
  onChange: (value: { label?: string; value?: string }) => void;
};

export const DropDown2: React.FC<DropDownProps> = ({
  items,
  selected,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <Box position={'relative'}>
      <FormControl fullWidth>
        <Select className={classes.cleanButton} value={selected}>
          {items?.map((item, i) => (
            <MenuItem
              key={i}
              value={item.value}
              onClick={() => onChange && onChange(item)}
            >
              {item?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
