import React, { useEffect, useState } from 'react';
import { Button, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useAuth } from '../../auth/auth-hook';
import AppleLogin from 'react-apple-login';
import jwt_decode from 'jwt-decode';
import { AppleUserType } from '../../auth/auth-types';
import {
  useCreateUserProviderMutation,
  useDeleteUserSocialProviderMutation,
} from '../../../shared/types/generated';
import { getCurrentUrl } from '../../auth/auth-utils';
import { openDialogAlert } from '../../alert/alert-actions';
import { DefaultDialog } from '../../../shared/components/dialog/DefaultDialog';
import { APP_ENV } from '../../../shared/constans';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    buttonConnect: {
      background: '#FA0000!important',
      color: 'white!important',
      width: '180px',
    },
    buttonDisconnect: {
      background: '!whiteimportant',
      color: '#FA0000!important',
      border: '1px solid #FA0000',
      width: '180px',
    },
    textSocial: {
      margin: '0px 0px 0px 15px!important',
    },
  }),
);

type AppleConnectButtonProps = {
  isConnect?: boolean;
};
export const AppleConnectButton: React.FC<AppleConnectButtonProps> = ({
  isConnect,
}) => {
  const classes = useStyles();
  const { user, refetch } = useAuth();
  const [appleUser, setAppleUser] = useState<AppleUserType | undefined | null>(
    undefined,
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [createAppleProvider] = useCreateUserProviderMutation({
    onCompleted: () => {
      setAppleUser(undefined);
      refetch();
      return openDialogAlert(
        'Se ha conectado tu cuenta de Apple correctamente',
      );
    },
  });

  const [deleteAppleProvider, { loading: loadingDelete }] =
    useDeleteUserSocialProviderMutation({
      onCompleted: () => {
        setIsOpen(false);
        refetch();
      },
    });

  useEffect(() => {
    if (appleUser) {
      createAppleProvider({
        variables: {
          data: {
            provider: 'APPLE',
            providerId: appleUser.email,
            userId: user?.id as number,
            urlRegister: getCurrentUrl(),
          },
        },
      });
    }
  }, [appleUser, user, createAppleProvider]);

  if (APP_ENV === 'local') return null;

  return isConnect ? (
    <>
      <Button
        className={classes.buttonDisconnect}
        onClick={() => setIsOpen(true)}
      >
        Desconectar
      </Button>
      <DefaultDialog
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        isLoading={loadingDelete}
        onSuccess={() =>
          deleteAppleProvider({
            variables: {
              data: {
                userId: user?.id,
                provider: 'APPLE',
              },
            },
          })
        }
      >
        <div style={{ fontSize: 18, padding: '20px 0' }}>
          ¿Estas seguro que deseas desconectar tu cuenta de Google?
        </div>
      </DefaultDialog>
    </>
  ) : (
    <AppleLogin
      clientId="mx.vang.suscriptores.client"
      redirectURI="https://suscriptores.vang.mx/apple/callback"
      callback={(data: {
        authorization: { code?: string; id_token?: string };
      }) => {
        if (data?.authorization && data?.authorization?.id_token) {
          const tokenDecoded: { email?: string } = jwt_decode(
            data?.authorization?.id_token,
          );

          setAppleUser({
            email: tokenDecoded.email,
          });
        }
      }} // Catch the response
      scope="name email"
      usePopup
      responseMode="query"
      render={(
        renderProps, //Custom Apple Sign in Button
      ) => (
        <Button className={classes.buttonConnect} onClick={renderProps.onClick}>
          Conectar
        </Button>
      )}
    />
  );
};
