import React, { ChangeEvent, useState } from 'react';
import { Grid, TextField, Box } from '@mui/material';
import { styled } from '@mui/styles';
import { Person } from '@mui/icons-material';
import { Key } from '@mui/icons-material';
//import EditIcon from '@mui/icons-material/Edit';
import { EmailOutlined } from '@mui/icons-material';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useCreateUserMutation } from '../../../shared/types/generated';
import { registerUserDataEvent } from '../auth-events';
import { useEvent } from '@cobuildlab/react-simple-state';
import { getCurrentUrl } from '../auth-utils';
import validator from 'validator';
type AuthRegisterFormProps = {
  onSuccess: (data: RegisterData) => void;
  //onBack?: () => void;
  //onBackHome?: () => void;
};
const StyledIconAvatar = styled(Person)({
  //cursor: 'pointer',
  color: '#999999',
  fontSize: '20px!important',
  paddingRight: '5px',
});
const StyledIcon = styled(EmailOutlined)({
  //cursor: 'pointer',
  color: '#999999',
  fontSize: '20px!important',
  paddingRight: '5px',
});

interface RegisterData {
  [key: string]: string | boolean | number;
}

const StyledButton = styled(ButtonDefault)({
  borderRadius: '5px!important',
  backgroundColor: '#FE0000!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#FFCCCC!important',
  },
  width: 200,
});

const StyledVisibilityIcon = styled(VisibilityIcon)({
  cursor: 'pointer',
  color: '#999999',
  fontSize: '20px!important',
});
const StyledIconKey = styled(Key)({
  //cursor: 'pointer',
  color: '#999999',
  fontSize: '20px!important',
  paddingRight: '5px',
});

export const AuthRegisterForm: React.FC<AuthRegisterFormProps> = ({
  onSuccess,
  //onBack,
  //onBackHome,
}) => {
  const { email } = useEvent(registerUserDataEvent);

  const [registerData, setRegisterData] = useState<RegisterData>({
    email: email ?? '',
    firstName: '',
    password: '',
  });
  const [passwordType, setPasswordType] = useState<string | undefined>(
    undefined,
  );
  const handleSubmit = (): boolean => {
    let checkData = true;
    Object.keys(registerData).forEach((key) => {
      if (!registerData[key] && checkData) checkData = false;
    });
    return checkData;
  };

  const onChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setRegisterData({
      ...registerData,
      [event.target.name]: event.target.value,
    });
  };

  const [createUserMutation, { loading }] = useCreateUserMutation({
    onCompleted: ({ createUser }) => {
      if (createUser?.id) {
        onSuccess(registerData);
      }
    },
    onError: () => {},
  });

  const createUser = (): void => {
    createUserMutation({
      variables: {
        data: {
          email: String(registerData?.email),
          name: String(registerData?.firstName),
          password: String(registerData?.password),
          provider: {
            provider: 'SYSTEM',
            urlRegister: getCurrentUrl(),
          },
        },
      },
    });
  };

  const handleVisibility = (): void => {
    if (!passwordType) setPasswordType('text');
    else setPasswordType(undefined);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        {/**
           * <Box mb={4}>
          <img src="/LOGO_NOROESTE.svg" alt="logo" width={225} height={25} />
          <StyledTypography variant="subtitle1">
            Crea una cuenta
          </StyledTypography>
        </Box>{' '}
           */}
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          //label={'Nombre'}

          placeholder={'Nombre de usuario'}
          name="firstName"
          size="small"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <StyledIconAvatar />,
            style: { fontSize: '18px', background: 'white', height: '48px' },
            //endAdornment: <StyledIcon onClick={() => onBack()} />,
          }}
          value={registerData?.firstName}
          onChange={(event) => onChange(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          value={registerData?.email}
          size="small"
          name="email"
          //label="Email"
          placeholder={'Direccion de correo electrónico'}
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            //readOnly: true,
            startAdornment: <StyledIcon />,
            style: { fontSize: '18px', background: 'white', height: '48px' },
          }}
          onChange={(event) => onChange(event)}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          //label={'Contraseña'}
          name="password"
          size="small"
          placeholder={'Contraseña'}
          type={passwordType ?? 'password'}
          value={registerData?.password}
          fullWidth
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <StyledVisibilityIcon onClick={() => handleVisibility()} />
            ),
            startAdornment: <StyledIconKey />,
            style: { fontSize: '18px', background: 'white', height: '48px' },
          }}
          onChange={(event) => onChange(event)}
        />
      </Grid>
      {/**
         * <Grid item xs={12}>
        <StyledBox
          color="#FE0000"
          textAlign="center"
          fontSize={18}
          onClick={() => onBackHome()}
        >
          {'< Volver a todas las opciones'}
        </StyledBox>
      </Grid>
         */}
      <Grid item xs={12}>
        <Box textAlign="center">
          <StyledButton
            variant="contained"
            size="small"
            fullWidth
            color="secondary"
            disabled={
              !handleSubmit() ||
              !validator.isEmail(registerData?.email as string)
            }
            onClick={() => createUser()}
            isLoading={loading}
            style={{ padding: '11px 0' }}
          >
            Siguiente
          </StyledButton>
        </Box>
      </Grid>
    </Grid>
  );
};
