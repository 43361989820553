import React from 'react';
import { Routes as RoutesComponent, Route } from 'react-router-dom';
import { Auth } from './modules/auth/Auth';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CookiesProvider } from 'react-cookie';
import { GOOGLE_API_KEY } from './shared/constans';
import { AuthFacebookCallback } from './modules/auth/AuthFacebookCallback';
import { FacebookLoginView } from './modules/auth/facebook/FacebookLoginView';
import { Session } from './modules/session/Session';
import { Layout } from './shared/components/layout/Layout';
import { Dashboard } from './modules/dashboard/Dashboard';
// import { ExternalAuth } from './modules/auth/external/ExternalAuth';
import { Library } from './modules/dashboard/Library';
import { Transactions } from './modules/dashboard/Transactions';
import { Subscriptions } from './modules/dashboard/Subscriptions';
import { PaymentMethods } from './modules/dashboard/PaymentMethods';
import { CardsDashboard } from './modules/dashboard/CardsDashboard';
import { History } from './modules/dashboard/History';
import { AuthLogout } from './modules/auth/AuthLogout';

export const Routes: React.FC = () => {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_API_KEY as string}>
      <CookiesProvider>
        <RoutesComponent>
          {/*<Route path="/external-auth" element={<ExternalAuth />} />*/}
          <Route path="/autenticacion" element={<Auth />} />
          <Route path="/facebook/login" element={<FacebookLoginView />} />
          <Route path="/callback/facebook" element={<AuthFacebookCallback />} />
          <Route path="/suscripciones" element={<Subscriptions />} />
          <Route
            path="*"
            element={
              <Session>
                <RoutesComponent>
                  <Route
                    path="/biblioteca"
                    element={
                      <Layout>
                        <Library num={3} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/transacciones"
                    element={
                      <Layout>
                        <Transactions num={2} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/detalles-de-facturacion"
                    element={
                      <Layout>
                        <CardsDashboard num={4} />
                      </Layout>
                    }
                  />

                  <Route
                    path="/metodos-de-pago"
                    element={
                      <Layout>
                        <PaymentMethods />
                      </Layout>
                    }
                  />
                  <Route
                    path="/historial"
                    element={
                      <Layout>
                        <History num={5} />
                      </Layout>
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <Layout>
                        <Dashboard num={1} />
                      </Layout>
                    }
                  />
                  <Route path="/logout" element={<AuthLogout />} />
                </RoutesComponent>
              </Session>
            }
          />
        </RoutesComponent>
      </CookiesProvider>
    </GoogleOAuthProvider>
  );
};
