import React, { useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import { Button } from '@mui/material';
import AppleLogin from 'react-apple-login';
import { AppleUserType } from '../auth-types';
import jwt_decode from 'jwt-decode';
import {
  useCreateUserMutation,
  useUserByEmailLazyQuery,
} from '../../../shared/types/generated';
import { getCurrentUrl } from '../auth-utils';
import { useStore } from '@cobuildlab/react-simple-state';
import { changeIsLoginStore } from '../auth-events';

const StyledButton = styled(Button)({
  position: 'relative',
  justifyContent: 'center!important',
  borderRadius: '5px!important',
  height: 45,
  paddingRight: '40px!important',
  border: '1px solid #FFCCCC!important',
  backgroundColor: 'white !important',
});

const StyledImage = styled('img')({
  marginRight: 10,
  position: 'relative',
  right: 3,
});

type AppleButtonProps = {
  onSuccess: (email: string) => void;
};
export const AppleButton: React.FC<AppleButtonProps> = ({ onSuccess }) => {
  const [user, setUser] = useState<AppleUserType | undefined | null>(undefined);
  const { isLogin } = useStore(changeIsLoginStore);
  const [createUserMutation] = useCreateUserMutation({
    onCompleted: ({ createUser }) => {
      if (createUser?.id) {
        if (onSuccess && createUser?.email) onSuccess(createUser?.email);
      }
    },
    onError: () => {},
  });

  const [fetchUser] = useUserByEmailLazyQuery({
    onCompleted: ({ userByEmail }) => {
      if (userByEmail?.id) {
        if (onSuccess && user?.email) onSuccess(user?.email);
      } else {
        createUserMutation({
          variables: {
            data: {
              email: String(user?.email),
              name: user?.email,
              password: `$%-/${user?.email}+2023,`,
              provider: {
                provider: 'APPLE',
                urlRegister: getCurrentUrl(),
                providerId: user?.email,
              },
            },
          },
        });
      }
    },
    onError: () => {},
  });

  useEffect(() => {
    if (user) {
      fetchUser({
        variables: {
          email: user?.email as string,
          isSocialProvider: true,
          provider: 'APPLE',
          providerId: user?.email as string,
        },
      });
    }
  }, [user, fetchUser]);
  return (
    <>
      <AppleLogin
        clientId="com.cuenta.noroeste.service"
        redirectURI="https://cuenta.noroeste.com.mx/apple/callback"
        callback={(data: {
          authorization: { code?: string; id_token?: string };
        }) => {
          if (data?.authorization && data?.authorization?.id_token) {
            const tokenDecoded: { email?: string } = jwt_decode(
              data?.authorization?.id_token,
            );

            setUser({
              email: tokenDecoded.email,
            });
          }
        }} // Catch the response
        scope="name email"
        usePopup
        responseMode="query"
        render={(
          renderProps, //Custom Apple Sign in Button
        ) => (
          <StyledButton
            type="button"
            className=""
            variant="outlined"
            fullWidth
            onClick={renderProps.onClick}
          >
            <StyledImage src="/apple.webp" width={20} height={20} alt="logo" />{' '}
            {`${isLogin ? 'ENTRAR' : 'REGISTRARME'}`} con Apple
          </StyledButton>
        )}
      />
    </>
  );
};
