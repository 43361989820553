import React, { useEffect } from 'react';
import { Container, Grid, Typography, Box, Button, Theme } from '@mui/material';
import { SubTypes } from '../../shared/components/subscritions/SubTypes';
import { createStyles, makeStyles } from '@mui/styles';
import { ItemSubscribeList } from './components/ItemSubscribeList';
import { useAuth } from '../../modules/auth/auth-hook';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
    },
    textCss: {
      fontWeight: 'bold !important',
      fontSize: '34px !important',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '22px !important',
      },
    },
  }),
);
import { useNavigate } from 'react-router-dom';
import { PixelFacebookEvent } from '../../shared/utils/PixelFacebookEvent';

export const Subscriptions: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { subscription } = useAuth();
  const query = new URLSearchParams(window.location.search);
  useEffect(() => {
    if (subscription) {
      navigate('/');
    }
  });
  return (
    <>
      <PixelFacebookEvent id="subscriptions" />
      <Container style={{ marginBottom: '50px' }}>
        <Button
          onClick={() => {
            if (query.has('redirect_url'))
              window.location.href = query.get('redirect_url') as string;
            else navigate('../');
          }}
          className={classes.cleanButton}
        >
          &lt;&lt; Atras
        </Button>
        <Grid
          container
          spacing={4}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item md={9}>
            <Typography
              style={{ fontSize: 32, fontWeight: 'bold' }}
              align={'center'}
              mb={2}
            >
              Ayuda al periodismo independiente
            </Typography>
            <Typography style={{ fontSize: 17 }} align={'center'} mb={2}>
              Nos aseguramos de que recibas información de alta calidad y
              confiabilidad. También, evitamos la publicidad invasiva y
              clickbait, nos ayuda a cubrir gastos de personal e infraestructura
              tecnológica.
            </Typography>
            <Typography style={{ fontSize: 17 }} align={'center'} mb={2}>
              Elige la <strong>suscripción digital</strong> ideal para ti y
              obtén acceso a todas nuestras publicaciones. Si requieres una{' '}
              <strong>suscripción de periódico impreso</strong>, comunícate con
              nosotros o envíanos un WhatsApp al{' '}
              <a
                href="https://api.whatsapp.com/send?phone=6691550281&text=%C2%A1Hola!%20%C2%BFMe%20podr%C3%ADan%20ayudar%3F%20Necesito%20m%C3%A1s%20informaci%C3%B3n"
                style={{ color: 'black' }}
                target="_blank"
              >
                6691550281
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
        <Box mt={'20px'}>
          <SubTypes></SubTypes>
        </Box>
        <Box maxWidth={'400px'} margin={'0 auto'}>
          <Typography className={classes.textCss} mb={2}>
            ¿Qué recibo?
          </Typography>
          <ItemSubscribeList value={'Contenidos sin restricciones'} />
          <ItemSubscribeList
            value={'Reportajes e investigaciones exclusivas antes que nadie'}
          />
          <ItemSubscribeList
            value={'Resumen de noticias generado por Inteligencia Artificial'}
          />
          <ItemSubscribeList
            value={'Lectura de noticias por narrador electrónico'}
          />
          <ItemSubscribeList value={'Menos publicidad y mejor experiencia'} />
          <ItemSubscribeList
            value={'Acceso a nuestro periódico virtual epaper'}
          />
          <ItemSubscribeList
            value={
              <Box display={'inline-flex'}>
                Acceso a nuestras apps&ensp;
                <a
                  target="_blank"
                  href="https://itunes.apple.com/mx/app/noroeste/id1220184484?mt=8"
                >
                  iOS
                </a>
                &ensp;y&ensp;
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.noroeste.norotest"
                >
                  Android
                </a>
                .
              </Box>
            }
          />
          {/** <ItemSubscribeList
            value={'Invitaciones a eventos exclusivos y promociones'}
          /> */}
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.noroeste.norotest"
            >
              <img src="/download_google.png" alt="" width={150}></img>
            </a>
            &ensp;&ensp;
            <a
              target="_blank"
              href="https://itunes.apple.com/mx/app/noroeste/id1220184484?mt=8"
            >
              <img src="/download_ios.svg" alt="" width={130} />
            </a>
            .
          </Box>
        </Box>
        <Box marginTop={'50px'} color={'#777777'} paddingBottom={'20px'}>
          <Typography textAlign={'center'}>
            Si tienes alguna pregunta o inquietud, ponte en contacto con
            nosotros a través del correo electrónico: premium@noroeste.com. O
            bien, comunícate al{' '}
            <a
              href="tel:+526677598100"
              target="_blank"
              className="text-[#222222] text-[12px] font-Century-Gothic"
            >
              6677598100
            </a>{' '}
            o al{' '}
            <a
              href="tel:+526699155200"
              target="_blank"
              className="text-[#222222] text-[12px] font-Century-Gothic"
            >
              6699155200
            </a>
            , extensión: 100 de lunes a viernes de 8:00 a 16:00 hrs. Por
            WhatsApp puedes dejarnos un mensaje las 24 horas al{' '}
            <a
              href="https://api.whatsapp.com/send?phone=6699155200&text=%C2%A1Hola!%20%C2%BFMe%20podr%C3%ADan%20ayudar%3F%20Necesito%20m%C3%A1s%20informaci%C3%B3n"
              className="ml-1 w-[60px] h-[60px] drop-shadow-lg pointer-events-auto"
              target="_blank"
            >
              {' '}
              6699155200
            </a>
            .
          </Typography>
        </Box>
      </Container>
    </>
  );
};
