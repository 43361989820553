import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';

type AuthForgotPasswordType = {
  onBack: () => void;
};
const StyledBox = styled(Box)({
  cursor: 'pointer',
  marginTop: '15px',
});
export const AuthPasswordHasChange: React.FC<AuthForgotPasswordType> = ({
  onBack,
}) => {
  return (
    <Box>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Box mb={4}>
            {/**
               * <img
              src="/LOGO_NOROESTE.svg"
              alt="logo"
              width={225}
              height={25}
              style={{ paddingBottom: '20px' }}
            />{' '}
               */}
            <Typography fontSize={'19px'} fontWeight={700}>
              La contraseña se ha cambiado correctamente.
            </Typography>
            {/**
               * <StyledTypography variant="subtitle1">
              La contraseña se ha cambiado correctamente.
            </StyledTypography
               */}
          </Box>
        </Grid>
        <StyledBox
          color="#FE0000"
          textAlign="center"
          fontSize={18}
          onClick={() => onBack()}
        >
          {'< Volver'}
        </StyledBox>
      </Grid>
    </Box>
  );
};
