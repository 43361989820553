import React from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  MenuItem,
  Menu,
  Theme,
  Avatar,
  Button,
  ListItemIcon,
  ListItemText,
  Hidden,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import PowerSettingsNewSharpIcon from '@mui/icons-material/PowerSettingsNewSharp';
import { useAuth } from '../../../modules/auth/auth-hook';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { openMobileMenu } from '../../../modules/dashboard/dashboard-actions';
import { useStore } from '@cobuildlab/react-simple-state';
import { openMobileMenuEvent } from '../../../modules/dashboard/dashboard-events';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    toolbar: {
      backgroundColor: 'white',
      boxShadow: 'none',
      color: 'black',
    },
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
      color: '#000!important',

      margin: '0 0',
    },
    menu: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      color: 'white!important',
      marginRight: '18px!important',
      marginLeft: '5px!important',
    },
    textRed: {
      color: '#FE0000',
    },
    groupButton: {
      borderBottom: '1px solid #999999!important',
      borderTop: '1px solid #CCCCCC!important',
      padding: '10px 0px 10px 0px',
      display: 'block!important',
    },
  }),
);

export const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { subscription, user } = useAuth();
  const classes = useStyles();
  const navigate = useNavigate();
  const { open } = useStore(openMobileMenuEvent);

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Hidden mdDown={true} mdUp={false}>
            <Box
              position={'absolute'}
              top={'0'}
              left={'0'}
              height={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              display={'flex'}
              width={'100%'}
            >
              <button
                className={classes.cleanButton}
                onClick={() =>
                  (window.location.href = 'https://noroeste.com.mx/')
                }
              >
                <img
                  src="/LOGO_NOROESTE.svg"
                  alt="logo"
                  width={225}
                  height={25}
                  style={{ flexGrow: 1 }}
                />
              </button>
            </Box>
          </Hidden>
          <Hidden mdDown={false} mdUp={true}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => openMobileMenu()}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          {user && (
            <Box
              display={'flex'}
              justifyContent={'end'}
              width={'100%'}
              alignItems={'center'}
            >
              <Hidden mdDown={true} mdUp={false}>
                {!subscription ? (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    disableElevation
                    style={{ textTransform: 'capitalize', height: 45 }}
                    onClick={() => navigate('/suscripciones')}
                  >
                    <Typography>
                      <Hidden lgDown={true} lgUp={false}>
                        Quiero{' '}
                      </Hidden>
                      acceso total
                    </Typography>
                  </Button>
                ) : null}
              </Hidden>
              <div className={classes.menu}>
                <Hidden mdDown={true} mdUp={false}>
                  <div>{user?.name || user?.email}</div>
                </Hidden>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <Avatar
                    sx={{ width: 30, height: 30 }}
                    src={user?.avatar as string}
                  />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem>
                    <ListItemText>
                      Usuario:{' '}
                      <span className={classes.textRed}>{user?.name}</span>{' '}
                    </ListItemText>
                  </MenuItem>
                  <div className={classes.groupButton}>
                    <MenuItem
                      onClick={() => {
                        navigate('/');
                        handleClose();
                      }}
                    >
                      {' '}
                      Mi perfil
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <a
                        style={{ textDecoration: 'none', color: 'black' }}
                        href="https://ac.noroeste.com.mx/autoayuda"
                        target="_blank"
                      >
                        Centro de ayuda
                      </a>
                    </MenuItem>
                  </div>
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <PowerSettingsNewSharpIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText onClick={() => navigate('/logout')}>
                      Cerrar sesión
                    </ListItemText>
                  </MenuItem>
                </Menu>
              </div>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
