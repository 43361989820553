import moment from 'moment';
import { Subscription } from '../../shared/types/generated';

export const getSubscriptionStatus = (
  subscription: Subscription | null,
): string => {
  const subscriptionStatus = subscription?.status;
  const startDate = moment(subscription?.startDate).format('YYYY/MM/DD');
  const endDate = moment(subscription?.endDate).format('YYYY/MM/DD');
  const compareDate = moment(new Date(), 'YYYY/MM/DD');

  if (subscriptionStatus === 'ACTIVE') return 'Activo';
  else if (subscriptionStatus === 'INACTIVE') return 'Vencido';
  else if (
    compareDate.isBetween(startDate, endDate) &&
    subscriptionStatus === 'CANCELLED'
  )
    return 'Activo';
  else return 'No renovar';
};
