import jwt_decode from 'jwt-decode';

const query = new URLSearchParams(window.location.search);

export const responseGoogle = (response: { credential: string }): void => {
  return jwt_decode(response.credential);
};

export const getCurrentUrl = (): string | null => {
  return query.has('current_url')
    ? query.get('current_url')
    : window.location.origin;
};

export const getFormType = (): string | null => {
  return query.has('form') ? query.get('form') : '';
};

export const getResetPasswordToken = (): string => {
  return query.has('password_reset_token')
    ? (query.get('password_reset_token') as string)
    : '';
};

export const getRedirectUrl = (): string => {
  return query.has('redirect_url')
    ? (query.get('redirect_url') as string)
    : '/';
};
export const getSuccessUrl = (): string => {
  return query.has('success_url') && query.get('success_url')
    ? (query.get('success_url') as string)
    : '/';
};
