import React from 'react';
//import { Header } from './Header';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { HeaderTwo } from './HeaderTwo';
import { Footer } from './Footer';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 0,
      paddingBottom: 0,
      background: '#F3F4F6',
      minHeight: '90vh',
    },
  }),
);

type LayoutProps = {
  children: React.ReactNode;
};
export const NewLayout: React.FC<LayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HeaderTwo />
      <div className={classes.root}>{children}</div>
      <Footer />
    </div>
  );
};
