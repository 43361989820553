import React, { useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import { Button } from '@mui/material';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { FACEBOOK_APP_ID } from '../../../shared/constans';
import { FacebookUserType } from '../auth-types';
import {
  useCreateUserMutation,
  useUserByEmailLazyQuery,
} from '../../../shared/types/generated';
import MobileDetect from 'mobile-detect';
import { getCurrentUrl } from '../auth-utils';
import { useNavigate } from 'react-router-dom';
import { openDialogAlert } from '../../alert/alert-actions';
import { changeIsLoginStore } from '../auth-events';
import { useStore } from '@cobuildlab/react-simple-state';

const StyledButton = styled(Button)({
  position: 'relative',
  justifyContent: 'center!important',
  borderRadius: '5px!important',
  backgroundColor: 'white !important',
  height: 45,
  border: '1px solid #FFCCCC!important',
});

const StyledImage = styled('img')({
  marginRight: 10,
});

type FacebookButtonProps = {
  onSuccess: (email?: string) => void;
};
export const FacebookButton: React.FC<FacebookButtonProps> = ({
  onSuccess,
}) => {
  const navigate = useNavigate();
  const agent = new MobileDetect(window.navigator.userAgent);
  const [user, setUser] = useState<FacebookUserType | undefined | null>(
    undefined,
  );
  const { isLogin } = useStore(changeIsLoginStore);
  const currentUrl = getCurrentUrl();
  const responseFacebook = (userInfo: ReactFacebookLoginInfo): void => {
    if (!userInfo.email)
      return openDialogAlert(
        'No pudimos crear una cuenta usando Facebook porque no hay una dirección de correo electrónico asociada con su cuenta de Facebook.',
      );

    setUser({
      id: userInfo.id,
      email: userInfo.email,
      name: userInfo.name,
      picture: {
        data: {
          url: userInfo.picture?.data.url,
        },
      },
    });
  };

  const [createUserMutation] = useCreateUserMutation({
    onCompleted: ({ createUser }) => {
      if (createUser?.id) {
        if (onSuccess && createUser?.email) onSuccess(createUser?.email);
      }
    },
    onError: () => {},
  });

  const [fetchUser] = useUserByEmailLazyQuery({
    onCompleted: ({ userByEmail }) => {
      if (userByEmail?.id) {
        if (onSuccess && user?.email) onSuccess(user?.email);
      } else {
        createUserMutation({
          variables: {
            data: {
              email: String(user?.email),
              name: user?.name,
              avatar: user?.picture?.data?.url,
              password: `$%-/${user?.email}+2023,`,
              provider: {
                provider: 'FACEBOOK',
                urlRegister: getCurrentUrl(),
                providerId: user?.email,
              },
            },
          },
        });
      }
    },
    onError: () => {},
  });

  useEffect(() => {
    if (user) {
      fetchUser({
        variables: {
          email: user?.email as string,
          isSocialProvider: true,
          provider: 'FACEBOOK',
          providerId: user?.email,
        },
      });
    }
  }, [user, fetchUser]);

  if (agent.mobile() && agent.phone() && agent.mobile()) {
    return (
      <StyledButton
        type="button"
        className=""
        variant="outlined"
        fullWidth
        onClick={() => navigate(`/facebook/login?state=${currentUrl}`)}
      >
        <StyledImage
          src="/facebook-icon.png"
          width={18}
          height={18}
          alt="logo"
        />
        {`${isLogin ? 'ENTRAR' : 'REGISTRARME'}`} con Facebook
      </StyledButton>
    );
  }

  return (
    <FacebookLogin
      appId={FACEBOOK_APP_ID as string}
      callback={responseFacebook}
      fields="id,name,email,picture"
      isMobile={false}
      autoLoad={false}
      render={(renderProps) => {
        return (
          <StyledButton
            type="button"
            className=""
            variant="outlined"
            fullWidth
            onClick={renderProps.onClick}
          >
            <StyledImage
              src="/facebook-icon.png"
              width={18}
              height={18}
              alt="logo"
            />
            {`${isLogin ? 'ENTRAR' : 'REGISTRARME'}`} con Facebook
          </StyledButton>
        );
      }}
    />
  );
};
