import React, { useEffect, useState } from 'react';
import { Button, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useAuth } from '../../auth/auth-hook';
import {
  FACEBOOK_APP_ID,
  FACEBOOK_CALLBACK,
  TARGET_ORIGIN_URL,
} from '../../../shared/constans';
import MobileDetect from 'mobile-detect';
import { getCurrentUrl } from '../../auth/auth-utils';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { FacebookUserType } from '../../auth/auth-types';
import {
  useCreateUserProviderMutation,
  useDeleteUserSocialProviderMutation,
} from '../../../shared/types/generated';
import { openDialogAlert } from '../../alert/alert-actions';
import { DefaultDialog } from '../../../shared/components/dialog/DefaultDialog';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    buttonConnect: {
      background: '#FA0000!important',
      color: 'white!important',
      width: '180px',
      height: 45,
    },
    buttonDisconnect: {
      background: '!whiteimportant',
      color: '#FA0000!important',
      border: '1px solid #FA0000',
      width: '180px',
      height: 45,
    },
    textSocial: {
      margin: '0px 0px 0px 15px!important',
    },
    disable: {
      background: '#efefef!important',
      opacity: '65% !important',
    },
  }),
);

type FacebookConnectButtonProps = {
  isConnect?: boolean;
  isActive?: boolean;
};
export const FacebookConnectButton: React.FC<FacebookConnectButtonProps> = ({
  isConnect,
  isActive = true,
}) => {
  const classes = useStyles();
  const { user, refetch } = useAuth();
  const agent = new MobileDetect(window.navigator.userAgent);
  const [facebookUser, setFacebookUser] = useState<
    FacebookUserType | undefined | null
  >();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [createFacebookProvider] = useCreateUserProviderMutation({
    onCompleted: () => {
      setFacebookUser(undefined);
      refetch();
      return openDialogAlert(
        'Se ha conectado tu cuenta de Facebook correctamente',
      );
    },
  });

  const [deleteFacebookProvider, { loading: loadingDelete }] =
    useDeleteUserSocialProviderMutation({
      onCompleted: () => {
        setIsOpen(false);
        refetch();
      },
    });

  const responseFacebook = (userInfo: ReactFacebookLoginInfo): void => {
    console.log('userInfo', userInfo);

    if (userInfo.id) {
      setFacebookUser({
        email: userInfo.email,
        name: userInfo.name,
        picture: {
          data: {
            url: userInfo.picture?.data.url,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (facebookUser) {
      createFacebookProvider({
        variables: {
          data: {
            provider: 'FACEBOOK',
            providerId: facebookUser.email,
            userId: user?.id as number,
            urlRegister: getCurrentUrl(),
          },
        },
      });
    }
  }, [facebookUser, user, createFacebookProvider]);

  if (agent.mobile() && agent.phone() && agent.mobile()) {
    return isConnect ? (
      <Button
        className={classes.buttonDisconnect}
        classes={{ disabled: classes.disable }}
        onClick={() =>
          deleteFacebookProvider({
            variables: {
              data: {
                userId: user?.id,
                provider: 'FACEBOOK',
              },
            },
          })
        }
        disabled={!isActive}
      >
        Desconectar
      </Button>
    ) : (
      <Button
        onClick={() =>
          window.parent.postMessage(
            {
              facebookUrl: `${FACEBOOK_CALLBACK}/facebook/login?state=${getCurrentUrl()}`,
            },
            TARGET_ORIGIN_URL,
          )
        }
        className={classes.buttonConnect}
      >
        Conectar
      </Button>
    );
  }

  return isConnect ? (
    <>
      <Button
        className={classes.buttonDisconnect}
        classes={{ disabled: classes.disable }}
        onClick={() => setIsOpen(true)}
        disabled={!isActive}
      >
        Desconectar
      </Button>
      <DefaultDialog
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        isLoading={loadingDelete}
        onSuccess={() =>
          deleteFacebookProvider({
            variables: {
              data: {
                userId: user?.id,
                provider: 'FACEBOOK',
              },
            },
          })
        }
      >
        <div style={{ fontSize: 18, padding: '20px 0' }}>
          ¿Estas seguro que deseas desconectar tu cuenta de Facebook?
        </div>
      </DefaultDialog>
    </>
  ) : (
    <FacebookLogin
      appId={FACEBOOK_APP_ID as string}
      callback={responseFacebook}
      fields="name,email,picture"
      isMobile={false}
      autoLoad={false}
      render={(renderProps) => {
        return (
          <Button
            className={classes.buttonConnect}
            onClick={renderProps.onClick}
          >
            Conectar
          </Button>
        );
      }}
    />
  );
};
