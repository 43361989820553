import React, { useRef, useState } from 'react';
import {
  Container,
  Divider,
  Grid,
  Typography,
  Theme,
  Box,
  Avatar,
  ListItemText,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useAuth } from '../auth/auth-hook';
import { UserProfileInfo } from './components/UserProfileInfo';
import { Menu } from './components/Menu';
import { GoogleConnectButton } from './components/GoogleConnectButton';
import GoogleIcon from '@mui/icons-material/Google';
import { FacebookConnectButton } from './components/FacebookConnectButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { AppleConnectButton } from './components/AppleConnectButton';
import { PixelFacebookEvent } from '../../shared/utils/PixelFacebookEvent';

type ProfileRowProps = {
  label?: string;
  value?: string;
};

const ProfileRow: React.FC<ProfileRowProps> = ({ label, value }) => {
  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={5} md={3}>
        <Typography variant="subtitle1" fontWeight="bold">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={7} md={9}>
        <Typography variant="subtitle1">{value}</Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    divider: {
      borderWidth: 2,
      borderColor: 'black!important',
      marginTop: '15px!important',
      marginBottom: '15px!important',
    },
    button: {
      borderColor: theme.palette.primary.main,
    },
    textRed: {
      color: '#FA0000',
    },
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
    },
    clearAllButton: {
      background: '#FE0000!important',
      border: 'none!important',
      cursor: 'pointer',
      padding: '0!important',
      width: '22px!important',
      height: '22px!important',
      borderRadius: '100%!important',
      color: 'white',
    },
    textSocial: {
      margin: '0px 0px 0px 15px!important',
    },
  }),
);
type ActiveNum = {
  num?: number;
};

export const Dashboard: React.FC<ActiveNum> = ({ num = 1 }) => {
  const classes = useStyles();
  const { user, socialProvider } = useAuth();
  const [changeData, setChangeData] = useState(false);
  const refFile = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const isGoogleConnected = (user?.provider || [])?.find(
    (prov) => prov?.provider === 'GOOGLE',
  );
  const isFacebookConnected = (user?.provider || [])?.find(
    (prov) => prov?.provider === 'FACEBOOK',
  );
  const isAppleConnected = (user?.provider || [])?.find(
    (prov) => prov?.provider === 'APPLE',
  );
  const SOCIAL_PROVIDERS = [
    {
      name: 'Google',
      isConnected: isGoogleConnected,
      button: (
        <GoogleConnectButton
          isConnect={!!isGoogleConnected}
          isActive={!!isFacebookConnected}
        />
      ),
      icon: <GoogleIcon fontSize="small" />,
    },
    {
      name: 'Facebook',
      isConnected: isFacebookConnected,
      button: (
        <FacebookConnectButton
          isConnect={!!isFacebookConnected}
          isActive={!!isGoogleConnected}
        />
      ),
      icon: <FacebookIcon fontSize="small" />,
    },
    {
      name: 'Apple',
      isConnected: (user?.provider || [])?.find(
        (prov) => prov?.provider === 'APPLE',
      ),
      button: <AppleConnectButton isConnect={!!isAppleConnected} />,
      icon: <AppleIcon fontSize="small" />,
    },
  ];

  return (
    <>
      <PixelFacebookEvent id="dashboard-perfil" />
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box>
              <Menu num={num} />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <input
              type="file"
              hidden
              ref={refFile}
              onChange={(event) => {
                setFile(
                  event.target.files && event.target.files.length
                    ? event.target.files[0]
                    : null,
                );
              }}
            />
            <Box display={'flex'} alignItems={'center'}>
              <Box position={'relative'} mt={'16px'}>
                <Avatar
                  sx={{ width: 60, height: 60 }}
                  src={
                    file ? URL.createObjectURL(file) : (user?.avatar as string)
                  }
                />
                <Box width={'22px'} position={'absolute'} bottom={-10}>
                  {changeData ? (
                    <button
                      className={classes.clearAllButton}
                      onClick={() => refFile.current?.click()}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </button>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
              <Box ml={2}>
                <Typography fontWeight="bold" variant="h6" mb={1}>
                  Hola y bienvenido{' '}
                  <span className={classes.textRed}>
                    {user?.name || user?.email}
                  </span>
                </Typography>
                <Typography variant="body2" style={{ fontSize: '16px' }}>
                  Acá encontraras información detallada de tu cuenta y mas
                </Typography>
              </Box>
            </Box>
            <Divider className={classes.divider} />
            <Box mb={2}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  fontWeight="bold"
                  variant="h6"
                  mb={1}
                  textTransform="uppercase"
                >
                  Datos de tu cuenta
                </Typography>
                <button
                  onClick={() => setChangeData(!changeData)}
                  className={classes.cleanButton}
                >
                  {!changeData ? <EditOutlinedIcon /> : <CloseOutlinedIcon />}
                </button>
              </Box>
              <Box mb={1} mt={1}>
                <Divider />
              </Box>

              {changeData ? (
                <UserProfileInfo
                  file={file}
                  disabledEmailPassword={
                    !!isGoogleConnected || !!isFacebookConnected
                  }
                />
              ) : (
                <>
                  <Box>
                    <ProfileRow label="Nombre de usuario" value={user?.name} />
                    <ProfileRow label="Email" value={user?.email} />
                    {socialProvider && (
                      <ProfileRow
                        label="Conectado con"
                        value={`Cuenta ${socialProvider}`}
                      />
                    )}
                  </Box>
                  <Grid container columnGap={'5'} mt={'30px'}>
                    <Grid item xs={12} sm={3}>
                      <Typography fontWeight={'600'}>Conectado con</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Grid container>
                        <Grid item xs={12}>
                          {SOCIAL_PROVIDERS.map((socialProviderItem, key) => (
                            <Grid container key={key} mb={2}>
                              <Grid item xs={6} sm={4}>
                                <Box
                                  height={'39px'}
                                  display={'flex'}
                                  alignItems={'center'}
                                >
                                  {socialProviderItem.icon}
                                  <ListItemText
                                    className={classes.textSocial}
                                    primary={socialProviderItem.name}
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sm={4}
                                alignItems="center"
                                display="flex"
                              >
                                {socialProviderItem.isConnected ? (
                                  <Box display={'flex'} alignItems={'center'}>
                                    <CheckCircleOutlineIcon
                                      fontSize="small"
                                      style={{ color: 'green' }}
                                    />
                                    <ListItemText
                                      style={{ color: 'green' }}
                                      className={classes.textSocial}
                                      primary="Conectada"
                                    />
                                  </Box>
                                ) : null}
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Box
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                  display={'flex'}
                                  width={'full'}
                                  border={'black'}
                                >
                                  {socialProviderItem.button}
                                </Box>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
