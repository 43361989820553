import { createAction } from '@cobuildlab/react-simple-state';
import { userUpdateErrorEvent, userUpdateEvent } from './user-events';
import { clientUpload } from '../../shared/apollo';
import {
  UpdateUserDocument,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  User,
} from '../../shared/types/generated';

export const updateUserAction = createAction(
  userUpdateEvent,
  userUpdateErrorEvent,
  async (variables: UpdateUserMutationVariables) => {
    const response = await clientUpload.mutate<
      UpdateUserMutation,
      UpdateUserMutationVariables
    >({
      mutation: UpdateUserDocument,
      variables,
    });

    return {
      user: response.data?.updateUser as User,
    };
  },
);
